import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const GlobalSearchAnimation = trigger('collapse', [
  state('false', style({ width: '300px' })),
  state('true', style({ width: '0' })),
  transition('0 => 1', animate('300ms ease-out')),
  transition('1 => 0', animate('300ms ease-in'))
]);
