import { NgModule  } from '@angular/core';

import { SharedModule }                          from '../shared/shared.module';
import { VehicleHistoryActionsComponent }        from './vehicle.history.actions.component';
import { VehicleHistoryRowExpandComponent }      from './vehicle.history.row.expand.component';
import { VehicleInfoComponent }                  from './vehicle.info.component';
import { VehicleInvoiceItemsRowExpandComponent } from './vehicle.invoice-items.row.expand.component';

@NgModule({
  declarations: [
    VehicleHistoryActionsComponent,
    VehicleHistoryRowExpandComponent,
    VehicleInfoComponent,
    VehicleInvoiceItemsRowExpandComponent
  ],
  entryComponents : [
    VehicleHistoryActionsComponent,
    VehicleInfoComponent,
    VehicleHistoryRowExpandComponent,
    VehicleInfoComponent,
    VehicleInvoiceItemsRowExpandComponent
  ],
  exports : [
    VehicleHistoryActionsComponent,
    VehicleHistoryRowExpandComponent,
    VehicleInfoComponent,
    VehicleInvoiceItemsRowExpandComponent
  ],
  imports: [
    SharedModule
  ]
})
export class VehiclesModule {}
