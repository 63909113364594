import CreateLoanCarEventHtml from './loan-car-schedule.create-event.html';
import ReturnLoanCarEventHtml from './templates/return.loan.car.event.html';
import ReviewLoanCarEventHtml from './templates/review.loan.car.event.html';
import StartLoanCarEventHtml  from './templates/start.loan.car.event.html';
import WindowFlipHtml         from '../core/templates/window-flip.html';

export function LoanCarEvents (
  $rootScope,
  $uibModal,
  $q,
  sessionObjAPI,
  workshopAPI
) {
  var LoanCarEvents = function (loanCar, Event, customer) {
    var self = this;

    this.loanCar = loanCar;
    this.Event = Event;
    this.customer = customer || {}

    this.crud = {
      save: function () {
        return self.loanCar.id ? 'patch' : 'post'
      }
    };

    this.routes = {
      save: function () {
        return self.loanCar.id ? '/loan_car' : '/loan_cars'
      }
    };

    this.init();

  };

  LoanCarEvents.prototype.init = function () {
    var self = this;

    // Set the local_start_time and local_return_time
    // based off the booking's start and return times.
    if (self.loanCar.local_booking_start_time && self.loanCar.local_booking_end_time) {
      self.loanCar.start_time = moment(self.loanCar.local_booking_start_time, 'YYYY-MM-DDThh:mm:ssZ').format('hh:mm:ss A');
      self.loanCar.return_time = moment(self.loanCar.local_booking_end_time, 'YYYY-MM-DDThh:mm:ssZ').format('hh:mm:ss A');

      console.log(self.loanCar);

      // Set the local_start_date and local_return_date
      // based off the booking's start and return dates.
      self.loanCar.start_date = moment(self.loanCar.local_booking_start_time, 'YYYY-MM-DDThh:mm:ssZ').format('YYYY-MM-DD');
      self.loanCar.return_date = moment(self.loanCar.local_booking_end_time, 'YYYY-MM-DDThh:mm:ssZ').format('YYYY-MM-DD');
    } else {
      self.loanCar.start_time = moment(self.loanCar.start_time, 'HH:mm:ss').format('hh:mm:ss A')
      self.loanCar.return_time = moment(self.loanCar.return_time, 'HH:mm:ss').format('hh:mm:ss A')
    };
  };

  LoanCarEvents.prototype.configureUpdate = function (loanCar) {
    var self = this

    self.loanCar.start_date = loanCar.local_booking_start_time ? moment(loanCar.local_booking_start_time).format() : null;

    if (loanCar.local_booking_start_time) {
      self.loanCar.start_date = moment(loanCar.local_booking_start_time).format('DD/MM/YYYY');
    }

  };


  LoanCarEvents.prototype.save = function (loanCar) {
    var self = this;

    self.loanCar.local_booking_start_time = self.loanCar.start_time ? self.loanCar.start_date + moment(self.loanCar.start_time, 'hh:mm:ss A').format('THH:mm:ssZ') : null;
    self.loanCar.local_booking_end_time = self.loanCar.return_time ? self.loanCar.return_date + moment(self.loanCar.return_time, 'hh:mm:ss A').format('THH:mm:ssZ') : null;


    return workshopAPI[self.crud.save()](self.routes.save(), self.loanCar, 'loan_car').then(function (data) {
      return $q.when();
    }, function (err) {
      return $q.reject(err);
    });
  };

  LoanCarEvents.prototype.createLoan = function () {
    var self = this

    return $uibModal.open({
      templateUrl: CreateLoanCarEventHtml,
      windowClass: 'x-lg-modal',
      controller: 'CreateLoanCarEventCtrl',
      resolve: {
        status: [ function () {
          return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
        }],
        customer: function () {
          return self.customer;
        },
        loan_vehicle: function () {
          return self.loanCar.loan_vehicle_id ? workshopAPI.get('/loan_vehicle', self.loanCar.loan_vehicle_id) : [];
        },
        states: function () {
          return workshopAPI.get('/system/state_types/', null, 'states');
        },
        event: function () {
          return self.Event;
        }
      }
    }).result;

  };

  LoanCarEvents.prototype.modifyLoan = function () {
    var self = this

    return $uibModal.open({
      templateUrl: CreateLoanCarEventHtml,
      windowClass: 'x-lg-modal',
      controller: 'ModifyLoanCarEventCtrl',
      resolve: {
        status: [ function () {
          return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
        }],
        customer: function () {
          return self.loanCar.customer_id ? workshopAPI.get('/customer', self.loanCar.customer_id) : [];
        },
        loan_car: function () {
          return self.loanCar;
        },
        loan_vehicle: function () {
          return self.loanCar.loan_vehicle_id ? workshopAPI.get('/loan_vehicle', self.loanCar.loan_vehicle_id) : [];
        },
        states: function () {
          return workshopAPI.get('/system/state_types/', null, 'states');
        },
        event: function () {
          return self.Event;
        },
        invoice: function () {
          return self.loanCar.invoice_id ? workshopAPI.get('/invoice', self.loanCar.invoice_id) : [];
        },
        available_vehicles: function () {
          return workshopAPI.get('/loan_vehicle/available_vehicles_list', [self.loanCar.local_booking_start_time, self.loanCar.local_booking_end_time, self.loanCar.id]);
        }
      }
    }).result;

  };

  LoanCarEvents.prototype.startLoan = function () {
    var self = this
    var company = sessionObjAPI.company();

    self.configureUpdate(self.loanCar)

    return $uibModal.open({
      controller       : 'StartLoanCarEventCtrl',
      templateUrl      : StartLoanCarEventHtml,
      windowClass      : 'x-lg-modal flip-container',
      windowTemplateUrl: WindowFlipHtml,
      resolve          : {
        company        : () => company,
        companyMessages: () => $rootScope.Company.getDefaultMessages(),
        customer       : () => self.loanCar.customer_id ? workshopAPI.get('/customer', self.loanCar.customer_id) : [],
        event          : () => self.Event,
        invoice        : () => self.loanCar.invoice_id ? workshopAPI.get('/invoice', self.loanCar.invoice_id) : [],
        loan_vehicle   : () => self.loanCar.loan_vehicle_id ? workshopAPI.get('/loan_vehicle', self.loanCar.loan_vehicle_id) : [],
        loan           : () => self.loanCar,
        serviceAdviser : () => {
          const service_adviser_id = self.loanCar.confirmed_by_before;

          return service_adviser_id
            ? workshopAPI.get('/service_adviser', self.loanCar.confirmed_by_before)
            : [];
        },
        states: () => workshopAPI.get('/system/state_types/', null, 'states'),
        status: () => workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes')
      }

    }).result;
  };

  LoanCarEvents.prototype.returnLoan = function () {
    var self = this

    return $uibModal.open({
      templateUrl: ReturnLoanCarEventHtml,
      windowClass: 'x-lg-modal',
      controller: 'ReturnLoanCarEventCtrl',
      resolve: {
        status: [ function () {
          return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
        }],
        loan_vehicle: function () {
          return self.loanCar.loan_vehicle_id ? workshopAPI.get('/loan_vehicle', self.loanCar.loan_vehicle_id) : [];
        },
        states: function () {
          return workshopAPI.get('/system/state_types/', null, 'states');
        },
        loan: function () {
          return self.loanCar;
        },
        serviceAdviserBefore: function () {
          var service_adviser_id = self.loanCar.confirmed_by_before
          return service_adviser_id ? workshopAPI.get('/service_adviser', self.loanCar.confirmed_by_before) : [];
        },
        serviceAdviserReturn: function () {
          var service_adviser_id = self.loanCar.confirmed_by_return
          return service_adviser_id ? workshopAPI.get('/service_adviser', self.loanCar.confirmed_by_return) : [];
        },
        invoice: function () {
          return self.loanCar.invoice_id ? workshopAPI.get('/invoice', self.loanCar.invoice_id) : [];
        },
        customer: function () {
          return self.loanCar.customer_id ? workshopAPI.get('/customer', self.loanCar.customer_id) : [];
        },
        event: function () {
          return self.Event;
        }

      }

    }).result;
  };

  LoanCarEvents.prototype.reviewLoan = function () {
    var self = this

    return $uibModal.open({
      templateUrl: ReviewLoanCarEventHtml,
      windowClass: 'x-lg-modal',
      controller: 'ReviewLoanCarEventCtrl',
      resolve: {
        status: [ function () {
          return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
        }],
        loan_vehicle: function () {
          return self.loanCar.loan_vehicle_id ? workshopAPI.get('/loan_vehicle', self.loanCar.loan_vehicle_id) : [];
        },
        states: function () {
          return workshopAPI.get('/system/state_types/', null, 'states');
        },
        loan: function () {
          return self.loanCar;
        },
        serviceAdviserBefore: function () {
          var service_adviser_id = self.loanCar.confirmed_by_before
          return service_adviser_id ? workshopAPI.get('/service_adviser', self.loanCar.confirmed_by_before) : [];
        },
        serviceAdviserReturn: function () {
          var service_adviser_id = self.loanCar.confirmed_by_return
          return service_adviser_id ? workshopAPI.get('/service_adviser', self.loanCar.confirmed_by_return) : [];
        },
        invoice: function () {
          return self.loanCar.invoice_id ? workshopAPI.get('/invoice', self.loanCar.invoice_id) : [];
        },
        customer: function () {
          return self.loanCar.customer_id ? workshopAPI.get('/customer', self.loanCar.customer_id) : [];
        },
        event: function () {
          return self.Event;
        }

      }

    }).result;
  };

  return LoanCarEvents;
}