import { CodeTypeModel }    from '../../app-lite/core/models/code-type';
import { UserModel }        from '../../app-lite/users/models/user';
import { XeroCompanyModel } from './models/xero.company';
import { XeroServiceAjs }   from '../../app-lite/xero/xero.service.ajs';
import { XeroTokensModel }  from './models/xero.tokens';

export class XeroSignupCtrl {
  block : ng.blockUI.BlockUIService;
  form  : ng.IFormController;
  tokens: XeroTokensModel;
  user  : UserModel;

  constructor (
    blockUI         : ng.blockUI.BlockUIService,
    private $state  : ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $window : ng.IWindowService,
    private messages: any,
    private xeroApi : XeroServiceAjs,
    public countries: Array<CodeTypeModel>
  ) {
    this.messages  = messages;
    this.xeroApi   = xeroApi;

    this.block = blockUI.instances.get('xeroSignupBlock');
    this.user  = {};

    this.init();
  }

  init () {
    if (this.xeroApi.needsAuthentication()) {
      this.block.start();

      this.$window.location.assign(this.xeroApi.getAuthenticationUrl());
    }
    else {
      this.block.start();

      this.xeroApi.getSignupTokens()
      .then(( tokens : XeroTokensModel ) => {
        this.tokens = tokens;

        this.$state.go('app.xero-signup', {
          code: null
        }, {
          inherit: false,
          reload : false
        });

        return this.xeroApi.getUserForSignup(this.tokens);
      })
      .then(( user : UserModel ) => {
        this.user = user;
      })
      .catch(( err : any ) => {
        this.messages.error(err);
      })
      .finally(() => {
        this.block.stop();

        // needed for username/password autofill weirdness
        this.$timeout(() => this.form.$setPristine());
      });
    }
  }

  signup () {
    let company : XeroCompanyModel;

    this.block.start();

    this.xeroApi.signup(this.user)
    .then(( result : XeroCompanyModel ) => {
      company = result;

      return this.xeroApi.grantAccess(company, this.tokens);
    })
    .then(( code : string ) => this.xeroApi.login(company, code))
    .then(( url : string ) => {
      this.$window.location.assign(url);
    })
    .catch(( err : any ) => {
      this.messages.error(err);
    })
    .finally(() => {
      this.block.stop();
    });
  }
}
