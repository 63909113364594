import 'angular';

import { ApiServiceAjs }        from '../api/api.service.ajs';
import { ConfirmService }       from '../messages/confirm.service.ajs';
import { MessagesServiceAjs }   from '../messages/messages.service.ajs';
import { ProductCategoryModel } from './models/product-category';
import { XeroServiceAjs }       from '../xero/xero.service.ajs';

interface ProductCategoriesScope extends ng.IScope {
  productCategories            : Array<ProductCategoryModel>;
  cancelEdit                   : ( category : ProductCategoryModel, index : number ) => void;
  crudGridAPI                  : any;
  editRow                      : ( category : ProductCategoryModel, index : number ) => void;
  getTaxRate                   : ( taxRate : any ) => any;
  init                         : () => void;
  isQuickbooksTaxTypeAvailable : () => boolean;
  isTaxRateAvailable           : () => boolean;
  isTaxRateEditable            : ( category : ProductCategoryModel, type : 'purchases' | 'sales' ) => boolean;
  isXeroTaxTypeAvailable       : () => boolean;
  previousValue                : ProductCategoryModel;
  removeRow                    : ( category : ProductCategoryModel, index : number ) => void;
  saveCategory                 : ( category : ProductCategoryModel, index : number ) => void;
  saveRow                      : ( category : ProductCategoryModel, index : number ) => void;
  taxRates                     : Array<any>;
  updateTax                    : ( category : ProductCategoryModel ) => void;
}

export function ProductCategoriesCtrl (
  $q           : ng.IQService,
  $rootScope   : any,
  $scope       : ProductCategoriesScope,
  $translate   : ng.translate.ITranslateService,
  bigDecimalApi: any,
  crudGridAPI  : any,
  messages     : MessagesServiceAjs,
  workshopAPI  : ApiServiceAjs,
  confirm      : ConfirmService,
  xeroApi      : XeroServiceAjs
) {
  $scope.crudGridAPI      = crudGridAPI();
  $scope.crudGridAPI.data = $scope.productCategories;
  $scope.previousValue    = {} as ProductCategoryModel;
  $scope.taxRates         = [];

  $scope.cancelEdit = function ( category : ProductCategoryModel, index : number ) {
    category = angular.copy($scope.previousValue);

    category.editing = true;

    $scope.crudGridAPI.data[index] = category;

    $scope.crudGridAPI.toggleEditMode(category, index);
  };

  $scope.editRow = function ( category : ProductCategoryModel, index : number ) {
    $scope.previousValue = angular.copy(category);

    $scope.crudGridAPI.editRow(category, index);
  };

  $scope.getTaxRate = function ( taxRate : any ) {
    return taxRate || taxRate === 0
      ? bigDecimalApi.get(taxRate)
      : null;
  };

  $scope.isQuickbooksTaxTypeAvailable = function () {
    return $rootScope.Company.hasIntegration('quickbooks')
      && !$rootScope.Company.hasIntegration('xero')
      && !$rootScope.Company.hasIntegration('myob');
  };

  $scope.isTaxRateAvailable = function () {
    return !$rootScope.Company.usesMultiTax()
      && !$rootScope.Company.hasIntegration('myob');
  };

  $scope.isTaxRateEditable = function () {
    return !$scope.isQuickbooksTaxTypeAvailable() && !$scope.isXeroTaxTypeAvailable();
  };

  $scope.isXeroTaxTypeAvailable = function () {
    return $rootScope.Company.hasIntegration('xero')
      && !$rootScope.Company.hasIntegration('quickbooks')
      && !$rootScope.Company.hasIntegration('myob');
  };

  $scope.removeRow = function ( category : ProductCategoryModel, index : number ) {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_TEMPLATE'))
    .then(() => $scope.crudGridAPI.removeRow(category, index, '/product_category', 'id'))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.PRODUCT_CATEGORY'), 'success');

      $scope.crudGridAPI.data.splice(index, 1);
    });
  };

  $scope.saveCategory = function ( category : ProductCategoryModel, index : number ) {
    const method = category.id ? 'patch' : 'post';
    const url    = category.id ? '/product_category' : '/product_categories';

    /**
     * When saving a new product category, the saved product category
     * is returned after the POST. However, when doing a PATCH on an
     * existing product category, it returns the Array of saved product
     * categories. So, we need to account for either return value here.
     *
     * REFERENCE: WOR-4192 : WOR-4885
     */
    workshopAPI[method](url, category, 'product_category')
    .then(( result : Array<ProductCategoryModel> | ProductCategoryModel ) => {
      if (angular.isArray(result)) {
        angular.extend(category, result.find(productCategory => productCategory.id === category.id));
      }
      else {
        $scope.crudGridAPI.data.push(result);
      }

      $scope.crudGridAPI.toggleEditMode(category, index);

      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.PRODUCT_CATEGORY'), 'success');
    })
    .catch(( err : any ) => {
      messages.error(err);
    });
  };

  $scope.saveRow = function ( category : ProductCategoryModel, index : number ) {
    if ( $rootScope.Company.hasIntegration('myob') ) {
      category.purchase_tax_rate = null;
      category.sales_tax_rate    = null;
    }

    const purchasesTaxRate = $scope.getTaxRate(category.purchase_tax_rate);
    const salesTaxRate     = $scope.getTaxRate(category.sales_tax_rate);

    $q.when()
    .then(() =>
      ( this.isTaxRateAvailable()
        && ( ( salesTaxRate && salesTaxRate.isZero() ) || ( purchasesTaxRate && purchasesTaxRate.isZero() ) )
      )
      ? confirm.generic($translate.instant('COMPANY_SPACE.LISTS.TAX_RATE_IS_ZERO'))
      : $q.when(true)
    )
    .then(() => {
      $scope.saveCategory(category, index);
    })
    .catch(() => {
      category.sales_tax_rate = salesTaxRate.isZero()
        ? $scope.previousValue.sales_tax_rate
        : category.sales_tax_rate;

      category.purchase_tax_rate = purchasesTaxRate.isZero()
        ? $scope.previousValue.purchase_tax_rate
        : category.purchase_tax_rate;

      $scope.crudGridAPI.data[index] = category;
    });
  };

  $scope.updateTax = function ( category : ProductCategoryModel ) {
    if ( $scope.isQuickbooksTaxTypeAvailable() ) {
      category.purchase_tax_rate = category.qbo_purchase_tax_type
        ? $scope.taxRates.find(rate => rate.id === category.qbo_purchase_tax_type).purchase_tax_value
        : null;

      category.sales_tax_rate = category.qbo_sales_tax_type
        ? $scope.taxRates.find(rate => rate.id === category.qbo_sales_tax_type).sales_tax_value
        : null;
    }
    else if ( $scope.isXeroTaxTypeAvailable() ) {
      category.purchase_tax_rate = category.xero_purchase_tax_type
        ? $scope.taxRates.find(rate => rate.tax_type === category.xero_purchase_tax_type).effective_rate
        : null;

      category.sales_tax_rate = category.xero_sales_tax_type
        ? $scope.taxRates.find(rate => rate.tax_type === category.xero_sales_tax_type).effective_rate
        : null;
    }
  };

  if ( $rootScope.Company.hasIntegration('quickbooks') ) {
    workshopAPI.get('/quickbooks_online/tax_rates')
    .then(( rates : any ) => {
      $scope.taxRates = rates.all_tax_rates;
    })
    .catch(( err : any ) => {
      messages.error(err);
    });
  }
  else if ( $rootScope.Company.hasIntegration('xero') ) {
    xeroApi.getTaxRates()
    .then(( rates : Array<any> ) => {
      $scope.taxRates = rates;
    })
    .catch(( err : any ) => {
      messages.error(err);
    });
  }
};