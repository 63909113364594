import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { TableControlComponent } from '../table-control.component';
import { TableService          } from '../../../table.service';
import { VendorModel }           from '../../../../vendors/models/vendor';
import { VendorSearchService }   from '../../../../vendors/vendor.search.service';

@Component({
  styles: [ require('../table-control.component.scss'), require('../../../../shared/controls/search-control/search-control.component.scss') ],
  template: require('./table.vendor-search.control.component.html')
})
export class TableVendorSearchControlComponent extends TableControlComponent {
  @Input() placement: string;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('$translate')
    public $translate          : ng.translate.ITranslateService,
    private vendorSearchService: VendorSearchService,
    tableService               : TableService,
  ) {
    super(tableService);
  }

  handleSelect ( vendor : VendorModel ) {
    this.onSelect.emit({
      index: this.index,
      row  : this.record,
      vendor
    });
  }

  formatter = ( vendor : VendorModel ) => {
    return typeof vendor === 'object'
      ? vendor.company_name
      : vendor;
  }

  search = ( query : string ) => {
    return this.vendorSearchService.search(query);
  }
}