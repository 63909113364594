import angular from 'angular';

import { CustomerPortalStates }       from './customer-portal.states.ajs';
import { CustomerPortalService }      from './customer-portal.service.ajs';
import { CustomerPortalSettingsCtrl } from './customer-portal.settings.ajs';

angular.module('app-lite.customer-portal', [])
.config(CustomerPortalStates)
.controller('CustomerPortalSettingsCtrl', CustomerPortalSettingsCtrl)
.service('customerPortalApi', CustomerPortalService);
