import StockTakeQuickAddHtml from './stock-take.quick-add.html';

export class StockTakeServiceAjs {
  constructor (
    private $uibModal: ng.ui.bootstrap.IModalService
  ) {}

  quickAdd () : ng.IPromise<any> {
    return this.$uibModal.open({
      templateUrl: StockTakeQuickAddHtml,
      windowClass: 'lg-modal',
      controller : 'StockTakeQuickAddCtrl'
    }).result;
  }
}