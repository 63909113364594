export function ReviewLoanCarInvoiceCtrl (
  $uibModalInstance, 
  $q, 
  $rootScope, 
  $scope, 
  $timeout, 
  confirm,
  customer,
  event,
  invoice,
  loan,
  loan_vehicle,
  LoanCarInvoices,
  messages,
  serviceAdviserBefore, 
  serviceAdviserReturn,
  sessionObjAPI,
  states,
  status,
  streamSearch,
  workshopAPI
) {
  
  $scope.loanStatusTypes = status;
  $scope.loan_vehicle = loan_vehicle;
  $scope.states = states;
  $scope.loan_car = loan;
  $scope.invoice = invoice;
  $scope.event = event;
  $scope.customer = customer;
  $scope.company = sessionObjAPI.company();
  if ($scope.company.company_variable_fields.plate_number_name) {
    $scope.regoLabel = $scope.company.company_variable_fields.plate_number_name;
  }
  else {
    $scope.regoLabel = sessionObjAPI.countrySettings().rego_label;
  }
  ;
  $scope.LoanCarInvoices = new LoanCarInvoices($scope.loan_car);
  $scope.serviceAdviserBefore = serviceAdviserBefore;
  $scope.serviceAdviserReturn = serviceAdviserReturn;
}
