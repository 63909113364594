import { Component }                              from '@angular/core';
import { ControlContainer, NgForm }               from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { DateControlComponent }     from './date-control.component';
import { ShortDateAdapter }         from './short-date.adapter';
import { ShortDateParserFormatter } from './short-date.parser';

@Component({
  selector   : 'wor-short-date-control',
  styles: [require('./date-control.component.scss')],
  template: require('./date-control.component.html'),

  providers: [
    {
      provide : NgbDateAdapter,
      useClass: ShortDateAdapter
    },
    {
      provide : NgbDateParserFormatter,
      useClass: ShortDateParserFormatter
    }
  ],

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class ShortDateControlComponent extends DateControlComponent {};
