import StaticLookupHtml from './grids.static-lookup.html';

export function StaticLookupService ($uibModal) {
  return function (items, options, config = {}) {
    return $uibModal.open({
      controller : 'StaticLookupLiteCtrl',
      templateUrl: StaticLookupHtml,
      windowClass: config.windowClass || 'lg-modal',
      resolve    : {
        items  : () => items,
        options: () => options
      }
    }).result;
  };
}