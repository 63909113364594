import 'angular';

import CustomerSearchResultsHtml    from '../customers/templates/customers.search.results.html';
import EditableCurrencyCellHtml     from '../grids/templates/cell.currency.editable.html';
import EmailPaymentReceiptHtml      from './templates/customer-payment.email-receipt.html';
import InvoicesForBillerPaymentHtml from '../invoices/invoices.for-biller-payment.html';
import InvoicesForPaymentHtml       from '../invoices/invoices.for-payment.html';
import RemoveRowCellHtml            from '../grids/templates/cell.remove-row.html';
import RowDynamicColorCellHtml      from '../grids/templates/row.dynamic-color.html';
import WindowFlipHtml               from '../core/templates/window-flip.html';

export function CustomerPaymentLiteCtrl (
  $q,
  $rootScope,
  $scope,
  $state,
  $stateParams,
  $translate,
  $uibModal,
  $window,
  API,
  assemblyPaymentsApi,
  bigDecimalApi,
  billerApi,
  confirm,
  customerPaymentApi,
  Customer,
  gridAPI,
  messages,
  messengerApi,
  sessionObjAPI,
  tillpaymentsApi,
  userApi,
  workshopAPI
) {
  $scope.customerSearchResultsTemplateUrl = CustomerSearchResultsHtml;

  $scope.balance         = '0';
  $scope.creditsToApply  = parseFloat($scope.customer.credit_balance) > 0;
  $scope.currency        = sessionObjAPI.currency();
  $scope.editable        = $scope.customer_payment ? $scope.customer_payment.status === 'O': true;
  $scope.itemsToDelete   = [];
  $scope.loaded          = true;
  $scope.methodsToDelete = [];
  $scope.paymentComplete = false;
  $scope.paymentMethod   = {};
  $scope.user            = sessionObjAPI.user();

  $scope.Customer = new Customer($scope.customer);

  $scope.grid = {
    data                   : 'customer_payment.customer_payment_items_attributes',
    enableMultiSelect      : false,
    enableCellEditOnFocus  : true,
    enableCellSelection    : true,
    enableRowSelection     : false,
    cellEditableCondition  : 'editable',
    virtualizationThreshold: 9999,
    rowHeight              : 43,
    rowTemplate            : RowDynamicColorCellHtml,
    columnDefs             : [
      {
        enableCellEdit: false,
        cellTemplate  : RemoveRowCellHtml,
        disabled      : !$scope.editable,
        width         : '43px'
      },
      {
        field         : 'invoice_number',
        displayName   : $translate.instant('GENERAL_SPACE.FIELD.REFERENCE'),
        enableCellEdit: false,
        cellClass     : 'Uppercase ReadOnly'
      },
      {
        field         : 'invoice_post_date',
        displayName   : $translate.instant('GENERAL_SPACE.FIELD.POST_DATE'),
        enableCellEdit: false,
        cellClass     : 'Capitalize ReadOnly',
        cellFilter    : 'date:"dd/MM/yyyy"',
        width         : '110px'
      },
      {
        field         : 'invoice_balance_due',
        displayName   : $translate.instant('GENERAL_SPACE.FIELD.BALANCE_DUE_LABEL'),
        enableCellEdit: false,
        cellClass     : 'align-right ReadOnly',
        cellFilter    : 'globalCurrency',
        width         : '125px'
      },
      {
        field               : 'amount',
        displayName         : $translate.instant('JS_SPACE.COLUMNS.APPLIED_AMOUNT'),
        enableCellEdit      : true,
        cellClass           : 'align-right',
        cellFilter          : 'globalCurrency',
        editableCellTemplate: EditableCurrencyCellHtml,
        width               : '150px'
      },
      {
        field         : 'getBalance()',
        displayName   : $translate.instant('JS_SPACE.COLUMNS.BALANCE'),
        enableCellEdit: false,
        cellClass     : 'align-right ReadOnly',
        cellFilter    : 'globalCurrency',
        width         : '110px'
      }
    ],
  };

  $scope.addCalcFunctions = function () {
    if ($scope.customer_payment.customer_payment_items_attributes.length) {
      angular.forEach($scope.customer_payment.customer_payment_items_attributes,row => {
        row.getBalance = function () {
          var status              = $scope.customer_payment.status;
          var invoice_balance_due = bigDecimalApi.get(row.invoice_balance_due).setScale(2, BigDecimal.ROUND_HALF_UP);
          var amount              = bigDecimalApi.get(row.amount).setScale(2, BigDecimal.ROUND_HALF_UP);

          if (invoice_balance_due.isLessThanOrEqualTo(BigDecimal.ZERO)) {
            return 0;
          }
          if (status == 'O') {
            return invoice_balance_due.subtract(amount).toString();
          }
          else {
            return invoice_balance_due.toString();
          }
        };
      });
    }
  };

  $scope.addInvoices = function (invoice) {
    var add = function (invoices) {
      if (!angular.isArray(invoices)) {
        invoices = [invoices];
      }

      angular.forEach(invoices, (invoice, i) => {
        if (!i && $scope.customer_payment.customer_payment_items_attributes[0].untouched) {
          $scope.customer_payment.customer_payment_items_attributes[0].invoice_id = invoice.id;
          $scope.customer_payment.customer_payment_items_attributes[0].invoice_number = invoice.invoice_number;
          $scope.customer_payment.customer_payment_items_attributes[0].invoice_balance_due = invoice.balance_due;
          $scope.customer_payment.customer_payment_items_attributes[0].invoice_post_date = invoice.post_date;
          $scope.customer_payment.customer_payment_items_attributes[0].invoice_total = invoice.total;
          $scope.customer_payment.customer_payment_items_attributes[0].amount = invoice.balance_due;
          $scope.customer_payment.customer_payment_items_attributes[0].untouched = false;
        }
        else {
          $scope.customer_payment.customer_payment_items_attributes.push({
            id                 : '',
            customer_payment_id: '',
            invoice_id         : invoice.id,
            invoice_number     : invoice.invoice_number,
            invoice_post_date  : invoice.post_date,
            invoice_total      : invoice.total,
            amount             : invoice.balance_due,
            invoice_balance_due: invoice.balance_due,
            untouched          : false,
            getBalance () {
              var invoice_balance_due = new BigDecimal( this.invoice_balance_due ? this.invoice_balance_due.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
              var amount              = new BigDecimal( this.amount ? this.amount.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);

              if (invoice_balance_due.isLessThanOrEqualTo(BigDecimal.ZERO)) {
                return 0;
              }

              return invoice_balance_due.subtract(amount).toString();
            }
          });
        }
      });
    };

    if (invoice) {
      return workshopAPI.get('/invoice', invoice).then(invoice => {
        add(invoice);
      }).catch(err => {
        messages.error(err);
      });
    }


    if ($scope.Customer.isBiller()) {
      $uibModal.open({
        controller       : 'InvoicesForBillerPaymentCtrl',
        controllerAs     : '$ctrl',
        templateUrl      : InvoicesForBillerPaymentHtml,
        windowClass      : 'lg-modal flip-container',
        windowTemplateUrl: WindowFlipHtml,
        resolve          : {
          billers: () => workshopAPI.get('/customers/biller_list', [
            $scope.customer.id,
            0,
            '*',
            'P',
            'I',
            '*',
            '*' ,
            '*',
            'company_name',
            'desc'
          ]),
          isVendor: false
        }
      })
      .result.then(invoices => add(invoices));
    }
    else {
      $uibModal.open({
        controller  : 'InvoicesForPaymentCtrl',
        controllerAs: '$ctrl',
        templateUrl : InvoicesForPaymentHtml,
        windowClass : 'x-lg-modal',
        resolve     : {
          customer: () =>  $scope.customer,
          invoices: workshopAPI => workshopAPI.get('/customers/invoice_list', [ $scope.customer.id, 0, '*', 'P', 'I', 'post_date', 'desc' ])
        }
      })
      .result
      .then(invoices => add(invoices));
    };
  };

  $scope.addItem = function () {
    $scope.customer_payment.customer_payment_items_attributes.push({
      id                 : '',
      customer_payment_id: '',
      invoice_id         : '',
      invoice_number     : '',
      invoice_post_date  : '',
      invoice_total      : 0.00,
      amount             : 0.00,
      invoice_balance_due: 0.00,
      untouched          : true,
      getBalance () {
        var invoice_balance_due = new BigDecimal( this.invoice_balance_due ? this.invoice_balance_due.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
        var amount              = new BigDecimal( this.amount ? this.amount.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);

        if (invoice_balance_due.isLessThanOrEqualTo(BigDecimal.ZERO)) {
          return 0;
        }
        return invoice_balance_due.subtract(amount).toString();
      }
    });
  };

  $scope.applyPayment = function () {
    if ($scope.paymentMethod.amount && $scope.paymentMethod.payment_method && $scope.paymentMethod.payment_method.id) {
      $scope.customer_payment.customer_payment_methods_attributes.push({
        customer_payment_id: $scope.paymentMethod.payment_method.id,
        payment_type       : $scope.paymentMethod.payment_method.payment_method,
        amount             : $scope.paymentMethod.amount,
        reference          : $scope.paymentMethod.reference,
        use_eftpos         : $scope.paymentMethod.payment_method.use_eftpos
      });

      $scope.paymentMethod = {};

      $scope.updateTotals();
    }
    else {
      messages.show($translate.instant('JS_SPACE.MESSAGES.SELECT_PAYMENT_TYPE_AMOUNT'), 'error');
    }

  };

  $scope.billerCreditList = function (invoice) {
    return $q.when()
    .then(() => {
      return invoice.customer_payment_items_attributes.length > 0 && invoice.customer_payment_items_attributes[0].invoice_id
        ? confirm.generic($translate.instant('JS_SPACE.CONFIRM.LOSS_OF_UNSAVED_INFORMATION'))
        : $q.when(true);
    })
    .then(() => $scope.save())
    .then(payment => {
      $state.go('app.customer-payment', {
        customer_id: payment.customer_id,
        id         : payment.id
      }, {
        inherit: false,
        reload : true
      });

      billerApi.openLookup($scope.customer.id, false);
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.customerSelected = function (customer) {
    $state.go(customer.is_branch_customer ? 'app.customer-branch-payment' : 'app.customer-payment', {
      customer_id: customer.id
    });
  };

  $scope.delete = function() {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_PAYMENT'))
    .then(() => workshopAPI.delete('/customer_payment', $scope.customer_payment.id))
    .then(() =>{
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.PAYMENT'), 'success');

      $scope.form.$setPristine();

      $state.go('app.transaction-center.jobs');
    })
    .catch(err =>{
      messages.error(err);
    });
  };

  $scope.deleteItems = function () {
    return ($scope.itemsToDelete.length || $scope.methodsToDelete.length) && $scope.customer_payment.id ?
      workshopAPI.patch('/customer_payment', {
        id                                 : $scope.customer_payment.id,
        amount                             : $scope.customer_payment.amount,
        customer_payment_items_attributes  : $scope.itemsToDelete,
        customer_payment_methods_attributes: $scope.methodsToDelete
      }, 'customer_payment').then(payment => {
        $scope.itemsToDelete   = [];
        $scope.methodsToDelete = [];

        return payment;
      }) : $q.when();
  };

  $scope.email = function (sendReceipt) {
    messengerApi.email({
      customer_payment_attachment_id: '',
      customer_payment_id           : $scope.customer_payment.id,
      email                         : $scope.customer.email,
      id                            : $scope.customer.id,
      message                       : $scope.company.customer_email_message,
      send_receipt                  : sendReceipt,
      subject                       : $translate.instant('JS_SPACE.EMAIL.VARIABLE_SUBJECT', {
        name: $scope.company.company_name,
        num : $scope.customer_payment.customer_payment_number ? ` number ${ $scope.customer_payment.customer_payment_number }` : '',
        type: 'Receipt'
      })
    }, {
      bodyParamName: 'customer',
      size         : 'lg',
      templateUrl  : EmailPaymentReceiptHtml,
      url          : '/customer_payment/email_receipt'
    })
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.EMAIL_SENT'), 'success');
    });
  };

  $scope.getApplied = function() {
    var total = BigDecimal.ZERO;

    angular.forEach($scope.customer_payment.customer_payment_items_attributes, row => {
      var amount = new BigDecimal( row.amount ? row.amount.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);

      total = total.add(amount);
    });

    return total ? total.toString(): '0';
  };

  $scope.getBalance = function () {
    var applied  = new BigDecimal($scope.customer_payment.applied_amount ? $scope.customer_payment.applied_amount.toString(): '0');
    var payments = BigDecimal.ZERO;

    angular.forEach($scope.customer_payment.customer_payment_methods_attributes, method => {
      var amount = new BigDecimal(method.amount.toString());

      payments = payments.add(amount);
    });

    return applied.subtract(payments).toString();
  };

  $scope.getPayments = function () {
    var payments = BigDecimal.ZERO;

    angular.forEach($scope.customer_payment.customer_payment_methods_attributes, method => {
      var amount = new BigDecimal(method.amount.toString());

      payments = payments.add(amount);
    });

    return payments.toString();
  };

  $scope.getRowColor = function (item, column) {
    const classes = gridAPI.getColumnClasses(column);

    if (!$scope.editable) {
      classes.push('readonly');
    }

    return classes;
  };

  $scope.handleLostTerminalTransaction = function ( payment = $scope.customer_payment ) {
    return tillpaymentsApi.processSurchargeAndPayment(payment, 'payment')
    .then(() => confirm.notify({ message: $translate.instant('COMPANY_SPACE.INTEGRATIONS.TILLPAYMENTS.PREVIOUS_PAYMENT_TRANSACTION_FOUND') }))
    .then(() => {
      $state.go('app.customer-payment', {
        customer_id: payment.customer_id,
        id         : payment.id
      }, {
        inherit: false,
        reload : true
      });

      return $q.reject();
    });
  };

  $scope.print = function () {
    if ($scope.editable) {
      confirm.generic($translate.instant('JS_SPACE.CONFIRM.MUST_PROCESS_PAYMENT_BEFORE_PRINTING'))
      .then(() => $scope.process())
      .then(customer_payment => {
        $window.open(`${API.url}/reports/jasper_customer_receipt/${customer_payment.id}.pdf`, '_blank' );
      })
      .catch(err => {
        messages.error(err);
      });
    }
    else {
      $window.open(`${API.url}/reports/jasper_customer_receipt/${$scope.customer_payment.id}.pdf`, '_blank' );
    }
  };

  $scope.printTerminalReceipt = function () {
    window.open(`${API.url}/reports/jasper_workshoppay_eftpos_receipt/${ $scope.customer_payment.id }.pdf`, '_blank' );
  };

  $scope.process = function () {
    const balance = new BigDecimal($scope.balance.toString());

    if (!balance.isZero()) {
      $scope.unresolvedBalance = true;

      messages.show('No Payments Exist. Please Apply Payments Totaling the Applied Total', 'error');
    }
    else {
      $q.when()
      .then(() => $rootScope.Company.hasIntegration('tillpayments-pos')
        ? tillpaymentsApi.doesLostTerminalPaymentExist($scope.customer_payment)
          .then(answer => answer
            ? $scope.handleLostTerminalTransaction($scope.customer_payment)
            : $q.when()
          )
        : $q.when()
      )
      .then(() => confirm.generic($translate.instant('JS_SPACE.CONFIRM.SAVE_PROCESS_PAYMENT')))
      .then(() => $scope.save())
      .then(payment => {
        $scope.customer_payment = payment;

        if ( $rootScope.Company.hasPOS() ) {
          const amount = customerPaymentApi.getPosPaymentTotal($scope.customer_payment);

          if ( amount ) {
            if ( $rootScope.Company.hasIntegration('tillpayments-pos') ) {
              return tillpaymentsApi.processTransaction(
                $scope.customer_payment,
                amount,
                'payment'
              );
            }

            if ( $rootScope.Company.hasIntegration('assembly-payments') ) {
              return assemblyPaymentsApi.processPayment($scope.customer_payment, undefined, amount);
            }
          }
        }

        return workshopAPI.get('/customer_payments/process_payment', payment.id);
      })
      .then(() => messages.show($translate.instant('JS_SPACE.MESSAGES.PROCESSED.PAYMENT'), 'success'))
      .catch(err => messages.error(err, 'error'))
      .finally(() => {
        $state.go('app.customer-payment', {
          customer_id: $scope.customer_payment.customer_id,
          id         : $scope.customer_payment.id
        }, {
          inherit: false,
          reload : true
        });
      });
    }
  };

  $scope.removeItem = function (row) {
    var deletedItem = $scope.customer_payment.customer_payment_items_attributes.splice(row.rowIndex, 1);

    if ($scope.customer_payment.id && row.entity.id) {
      deletedItem[0]._destroy = '1';
      $scope.itemsToDelete    = $scope.itemsToDelete.concat(deletedItem);
    }
  };

  $scope.removePaymentMethod = function (index) {
    if (!$scope.editable) {
      return;
    }

    var deletedItem = $scope.customer_payment.customer_payment_methods_attributes.splice(index, 1);

    if ($scope.customer_payment.id && deletedItem[0].id) {
      deletedItem[0]._destroy = '1';
      $scope.methodsToDelete  = $scope.methodsToDelete.concat(deletedItem);
    }

    $scope.updateTotals();
  };

  $scope.save = function () {
    const method = $scope.customer_payment.id ? 'patch': 'post';
    const url    = $scope.customer_payment.id ? '/customer_payment': '/customer_payments';

    var applied      = new BigDecimal($scope.getApplied());
    var payments     = new BigDecimal($scope.getPayments());
    var appliedCheck = applied - payments;

    if ($scope.form.$invalid) {
      messages.clientError($scope.form);

      return $q.reject();
    }

    if (payments > 0 && appliedCheck != 0){
      messages.show($translate.instant('JS_SPACE.MESSAGES.PAYMENT_EXISTS'), 'error');

      $scope.unresolvedBalance = true;

      return $q.reject();
    }

    return $scope.deleteItems()
    .then(() => workshopAPI[method](url, $scope.customer_payment, 'customer_payment', 'customer_payment_items_attributes'))
    .then(payment => {
      $scope.form.$setPristine();

      return $q.when(payment);
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.submit = function () {
    $scope.save()
    .then(payment => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.PAYMENT'), 'success');

      $state.go('app.customer-payment', {
        customer_id: payment.customer_id,
        id         : payment.id
      }, {
        inherit: false,
        reload : true
      });
    });
  };

  $scope.updateTotals = function () {
    $scope.customer_payment.applied_amount = $scope.getApplied();
    $scope.customer_payment.amount         = $scope.customer_payment.applied_amount;
    $scope.balance                         = $scope.getBalance();

    $scope.paymentMethod.amount = $scope.balance;

    const balance = new BigDecimal($scope.balance);

    if (balance.isZero()) {
      $scope.unresolvedBalance = false;
    }
  };

  $scope.viewPaymentMethods = function () {
    customerPaymentApi.viewPaymentMethods($scope.customer_payment);
  };

  $scope.void = function () {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.VOID_PAYMENT')).then(() => {
      workshopAPI.get('/customer_payments/void_payment', $scope.customer_payment.id).then(() => {
        messages.show($translate.instant('JS_SPACE.MESSAGES.VOIDED.PAYMENT'), 'success');

        $state.go('app');
      }).catch(err => {
        messages.error(err);
      });
    });
  };

  if ($stateParams.invoice_id && !$scope.customer_payment.id) {
    $scope.addInvoices($stateParams.invoice_id);
  }

  $scope.$watch('customer_payment.customer_payment_items_attributes', (items, oldItems) => {
    if (!items.length) {
      $scope.addItem();
    }
    else if (items !== oldItems) {
      $scope.updateTotals();
    }
  }, true);

  workshopAPI.get('/invoice_payment_methods')
  .then(methods => {
    $scope.payment_methods = methods;

    $scope.paymentMethod.payment_method = methods[0];
  });
}
