import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taxType'
})
export class TaxTypePipe implements PipeTransform {
  transform ( value : string, types : Array<any>, key = 'id' ) : string {
    const type = types.find(option => option[key] === value);

    return type
      ? type.name
      : '';
  }
}