import angular from 'angular';

import { CodeTypeModel }                from '../core/models/code-type';
import { InspectionTemplateGroupModel } from './models/inspection.template.group';
import { InspectionTemplateItemModel }  from './models/inspection.template.item';
import { InspectionTemplateModel }      from './models/inspection.template';

export function InspectionTemplateService (
  InspectionTemplateGroup: any,
  workshopAPI            : any,
) {
  const InspectionTemplate = function (
    template: InspectionTemplateModel,
    groups  : Array<CodeTypeModel>,
    types   : Array<CodeTypeModel>
  ) {
    this.comment1_label = template.comment1_label;
    this.comment2_label = template.comment2_label;
    this.description    = template.description;
    this.id             = template.id;
    this.name           = template.name;

    this.groupOptions   = groups;
    this.groupOrderings = [];
    this.groups         = this.initializeGroups(template.inspection_template_items_attributes);
    this.groupsToDelete = [];
    this.types          = types;

    this.resetOrderings();
  };

  InspectionTemplate.prototype.copy = function ( name : string ) {
    const self = this;

    return workshopAPI.post('/inspection_templates/copy_inspection_template', { id: self.id, name });
  };

  InspectionTemplate.prototype.initializeGroups = function ( items : Array<InspectionTemplateItemModel> ) {
    const self = this;

    var groupCodes: Array<string> = [];
    var groups    : Array<InspectionTemplateGroupModel> = [];

    if (!items || !items.length) {
      return [];
    }

    angular.forEach(items, ( item : InspectionTemplateItemModel ) => {
      if (groupCodes.indexOf(item.inspection_group) < 0) {
        groupCodes.push(item.inspection_group);
      }
    });

    for (let i = 0; i < groupCodes.length; i++) {
      const matchingItems  = items.filter(( item : InspectionTemplateItemModel ) => item.inspection_group === groupCodes[i]);
      const matchingOption = self.groupOptions.filter(( option : CodeTypeModel ) => option.code === groupCodes[i])[0] || {};
      groups.push(new InspectionTemplateGroup({
        code       : matchingOption.code,
        description: matchingOption.description,
        items      : matchingItems,
        ordering   : matchingItems[0].group_ordering
      }, self.types));
    }

    return groups;
  };

  InspectionTemplate.prototype.resetOrderings = function () {
    const self = this;

    self.groupOrderings = [];

    angular.forEach(self.groups, ( group : InspectionTemplateGroupModel, index : number) => {
      self.groupOrderings.push(index + 1);
    });
  };

  InspectionTemplate.prototype.orderGroups = function () {
    const self = this;

    self.groups.sort(( groupA : InspectionTemplateGroupModel, groupB : InspectionTemplateGroupModel ) => groupA.ordering - groupB.ordering);

    angular.forEach(self.groups, ( group : InspectionTemplateGroupModel, index : number ) => {
      group.ordering = index + 1;
    });
  };

  InspectionTemplate.prototype.addGroup = function ( group : InspectionTemplateGroupModel ) {
    const self = this;

    group.ordering = self.groups.length + 1;

    self.groups.push(new InspectionTemplateGroup(group, self.types));
    self.groupOrderings.push(self.groups.length);
  };

  InspectionTemplate.prototype.removeGroup = function ( index : number ) {
    const self = this;
    var group  = self.groups.splice(index, 1)[0];

    angular.forEach(group.items, ( item : InspectionTemplateItemModel ) => {
      item._destroy = '1';
    });

    self.groupsToDelete.push(group);

    self.resetOrderings();
    self.orderGroups();
  };

  InspectionTemplate.prototype.reorderGroup = function ( group : InspectionTemplateGroupModel ) {
    const self = this;

    var matchedIndex = 0;
    var filteredGroup = self.groups.filter(( item : InspectionTemplateGroupModel ) => item.code !== group.code);

    for (let i = matchedIndex; i < filteredGroup.length; i++) {
      filteredGroup[i].ordering++;
    }

    angular.forEach(group.items, ( item : InspectionTemplateItemModel ) => {
      item.group_ordering = group.ordering;
    });

    filteredGroup.push(group);

    self.groups = filteredGroup;

    self.orderGroups();
  };

  InspectionTemplate.prototype.save = function () {
    const self = this;

    var template : InspectionTemplateModel = {
      id                                  : self.id,
      name                                : self.name,
      description                         : self.description,
      comment1_label                      : self.comment1_label,
      comment2_label                      : self.comment2_label,
      inspection_template_items_attributes: []
    };

    angular.forEach(self.groups, ( group : InspectionTemplateGroupModel ) => {
      template.inspection_template_items_attributes = template.inspection_template_items_attributes.concat(group.items, group.itemsToDelete);
    });

    angular.forEach(self.groupsToDelete, ( group : InspectionTemplateGroupModel ) => {
      template.inspection_template_items_attributes = template.inspection_template_items_attributes.concat(group.items);
    });

    return self.id
     ? workshopAPI.patch('/inspection_template', template, 'inspection_template')
     : workshopAPI.post('/inspection_templates', template, 'inspection_template');
  };

  return InspectionTemplate;
};