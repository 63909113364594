export function LoanVehiclesCtrl (
  $scope,
  $translate,
  loan_vehicles,
  LoanCar,
  sessionObjAPI,
  tableApi,
  workshopAPI
) {
  $scope.company       = sessionObjAPI.company();
  $scope.loan_vehicles = loan_vehicles;
  $scope.LoanCar       = new LoanCar();
  $scope.tableCacheKey = 'loanVehicles';

  if ($scope.company.company_variable_fields.plate_number_name) {
    $scope.regoLabel = $scope.company.company_variable_fields.plate_number_name;
  }
  else {
    $scope.regoLabel = sessionObjAPI.countrySettings().rego_label;
  };

  $scope.filtering = tableApi.setFiltersFromCache($scope.tableCacheKey, {
    value: ''
  });

  $scope.manageLoanVehicle = function ( id ) {
    return $scope.LoanCar.manageLoanVehicle(id);
  };

  $scope.tableSettings = {
    cache: {
      enabled: true,
      key    : $scope.tableCacheKey
    },
    columns: [
      {
        classes: 'all-caps',
        field  : 'plate_number',
        header : {
          text: $scope.regoLabel
        }
      },
      {
        field : 'make',
        header: {
          text: $translate.instant('VEHICLE_SPACE.MAKE')
        }
      },
      {
        field : 'model',
        header: {
          text: $translate.instant('VEHICLE_SPACE.MODEL')
        }
      },
      {
        field : 'colour',
        header: {
          text: $translate.instant('VEHICLE_SPACE.COLOUR')
        }
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_LOAN_VEHICLES_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : tableApi.getCachedPaging($scope.tableCacheKey).pageSize || 10,
      pageSizes : [ 10, 25, 50 ],
      queryCount: () => workshopAPI.get('/filters/count', [
        $scope.filtering.value || '*',
        'loan_vehicles',
        false
      ])
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: tableApi.setSortingFromCache($scope.tableCacheKey, {
      direction: 'asc',
      field    : 'plate_number'
    })
  };

  $scope.onRowDoubleClick = function (event) {
    $scope.manageLoanVehicle(event.row.id);
  };

  $scope.getLoanVehicles = ( params ) => {
    return workshopAPI.stream('/filters', [
      '*',
      'loan_vehicles',
      params.offset,
      params.pageSize,
      params.field,
      params.direction,
      false
    ])
    .then(function (vehicles) {
      $scope.loan_vehicles = vehicles;

      return vehicles;
    });
  };
}