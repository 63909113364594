import { Injector, NgModule } from '@angular/core';

import { CustomerPaymentMethodPipe } from './customer-payment.method.pipe';
import { CustomerPaymentService }    from './customer-payment.service.ajs';
import { CustomerPaymentStatusPipe } from './customer-payment.status.pipe';

@NgModule({
  declarations: [
    CustomerPaymentMethodPipe,
    CustomerPaymentStatusPipe
  ],
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : CustomerPaymentService,
      useFactory: ( $injector : Injector ) => $injector.get('customerPaymentApi')
    },

    CustomerPaymentMethodPipe,
    CustomerPaymentStatusPipe,

    // Pipes must also be provided via string
    // if they are going to be used in a table
    // column. This allows the injector to be
    // able to load a pipe dynamically in the
    // TableCellComponent.
    {
      provide : 'customerPaymentMethod',
      useClass: CustomerPaymentMethodPipe
    },
    {
      provide : 'customerPaymentStatus',
      useClass: CustomerPaymentStatusPipe
    }
  ]
})
export class CustomerPaymentsModule {}