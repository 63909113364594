import { CodeTypeModel }  from '../../app-lite/core/models/code-type';
import { UserModel }      from '../../app-lite/users/models/user';
import { XeroServiceAjs } from '../../app-lite/xero/xero.service.ajs';

export class XeroCountryCtrl {
  form : ng.IFormController;
  user : UserModel;

  constructor (
    private $window : ng.IWindowService,
    private messages: any,
    private xeroApi : XeroServiceAjs,
    public countries: Array<CodeTypeModel>
  ) {}

  goToCountry () {
    this.xeroApi.getSignupUrlFromCountry(this.user.country)
    .then(( url : string ) => {
      this.$window.location.assign(url);
    })
    .catch(err => {
      this.messages.error(err);
    });
  }
}
