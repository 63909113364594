export function LoanVehicleCtrl (
  $uibModalInstance, 
  $q, 
  $rootScope,
  $state, 
  $scope, 
  $timeout,
  $translate, 
  confirm,
  loan_vehicle,
  messages,
  sessionObjAPI,
  status, 
  workshopAPI
) {
  
  $scope.vehicleStatusTypes = status;
  $scope.company = sessionObjAPI.company();
  
  if ($scope.company.company_variable_fields.plate_number_name) {
    $scope.regoLabel = $scope.company.company_variable_fields.plate_number_name;
  }
  else {
    $scope.regoLabel = sessionObjAPI.countrySettings().rego_label;
  };
  
  $scope.loan_vehicle = loan_vehicle ||  {
    loan_vehicle_status: 'I'
  }
  
  //$scope.loan_vehicle = loan_vehicle ? loan_vehicle : {
  //  loan_vehicle_status: 'P'
  //}
  
  $scope.save = function () {
    var url = $scope.loan_vehicle.id ? '/loan_vehicle' : '/loan_vehicles';
    var method = $scope.loan_vehicle.id ? 'patch' : 'post';
    
    workshopAPI[method](url, $scope.loan_vehicle, 'loan_vehicle').then(function (vehicle) {
      //workshopAPI.post('/loan_vehicles', $scope.loan_vehicle, 'loan_vehicle').then(function() {
      $uibModalInstance.close()
    }).then(function () {
      $state.reload();
      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.LOAN_VEHICLE'), 'success');  
    }).catch(function (err) {
      messages.error(err);
    });
  }
  
  $scope.delete = function () {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_LOAN_VEHICLE')).then(function () {
      return workshopAPI.delete('/loan_vehicle', $scope.loan_vehicle.id);
    }).then(function () {
      $uibModalInstance.close()
      $state.reload();
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.LOAN_VEHICLE'), 'success');
    }).catch(function (err) {
      messages.error(err);
    });
  }
  
}