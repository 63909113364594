import { Component, Inject, OnInit } from '@angular/core';
import { NgbModal }                  from '@ng-bootstrap/ng-bootstrap';
import { UIRouter }                  from '@uirouter/core';

import { ApiService }                from '../api/api.service';
import { ConfirmService }            from '../messages/confirm.service.ajs';
import { MessagesServiceAjs }        from '../messages/messages.service.ajs';
import { MyobService }               from '../myob/myob.service';
import { PluralizePipe }             from '../shared/pipes/pluralize.pipe';
import { QuickbooksService }         from '../quickbooks/quickbooks.service';
import { SessionServiceAjs }         from '../sessions/session.service.ajs';
import { SessionWarningModel }       from '../sessions/models/session.warning';
import { SidebarService }            from '../sidebar/sidebar.service';
import { SupportResourcesComponent } from '../support/support.resources.component';
import { XeroServiceAjs }            from '../xero/xero.service.ajs';

@Component({
  selector   : 'wor-header',
  styles: [ require('./header.component.scss') ],
  template: require('./header.component.html')
})
export class HeaderComponent implements OnInit {
  companyService   : any;
  myobService      : any;
  quickbooksService: any;
  warnings         : Array<SessionWarningModel>;

  constructor (
    @Inject('$rootScope')
    public $rootScope                   : any,
    @Inject('$translate')
    public $translate                   : ng.translate.ITranslateService,
    private apiService                  : ApiService,
    @Inject('businessIntelligenceApi')
    private businessIntelligenceService : any,
    private confirmService              : ConfirmService,
    private messagesService             : MessagesServiceAjs,
    private modalService                : NgbModal,
    private Myob                        : MyobService,
    public pluralizePipe                : PluralizePipe,
    private Quickbooks                  : QuickbooksService,
    private router                      : UIRouter,
    public sessionService               : SessionServiceAjs,
    private sidebarService              : SidebarService,
    private xeroService                 : XeroServiceAjs
  ) {}

  ngOnInit () : void {
    this.companyService    = this.$rootScope.Company;
    this.myobService       = new this.Myob();
    this.quickbooksService = new this.Quickbooks();
    this.warnings          = this.sessionService.getWarnings();
  }

  create ( state = 'app' ) : void {
    this.router.stateService.go(state, undefined, {
      inherit: false
    });

    this.resetSidebar();
  }

  dismissWarning ( warning = { id: '*' }) : void {
    this.apiService.get('/company/dismiss_notification', warning.id)
    .then(( warnings : Array<SessionWarningModel> ) => {
      this.warnings = warnings;
    });
  }

  hasRights ( module : string ) : boolean {
    return this.sessionService.hasRights(module);
  }

  logout () : void {
    this.apiService.delete('/sessions')
    .finally(() => {
      this.businessIntelligenceService.logout();

      this.sessionService.clear();

      window.location.assign('login.html');
    });
  }

  resetSidebar () : void {
    this.sidebarService.reset();
  }

  syncMyob () {
    if (this.companyService.isLinkedToBranch() && this.companyService.isSharedAccounting() && !this.companyService.company.last_myob_sync_down) {
      this.confirmService.generic(this.$translate.instant('JS_SPACE.CONFIRM.LINK_TOOL'), undefined, {
        cancelText : 'GENERAL_SPACE.BUTTON.CONTACTS_NO',
        confirmText: 'GENERAL_SPACE.BUTTON.CONTACTS_YES',
        size       : 'lg'
      }).then(() => {
        this.router.stateService.go('app.branches.contacts');
      })
      .catch(() => {
        this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.CONFIRM_SYNC'))
        .then(() => this.myobService.sync())
        .then(() => {
          this.messagesService.show(this.$translate.instant('JS_SPACE.MYOB.SYNC_CONFIRM'), 'success');
        })
        .catch(() => {
          this.apiService.get('myob/ignore_sync_before')
          .then(() => this.myobService.sync())
          .then(() => {
              this.messagesService.show(this.$translate.instant('JS_SPACE.MYOB.SYNC_CONFIRM'), 'success');
          });
        });
      });
    }
    else {
      return this.myobService.sync()
      .then(() => {
        this.messagesService.show(this.$translate.instant('JS_SPACE.MYOB.SYNC_CONFIRM'), 'success');
      });
    }
  }

  syncQuickbooks () {
    if (this.companyService.isLinkedToBranch() && this.companyService.isSharedAccounting() && !this.companyService.company.last_qbo_sync_down) {
      this.confirmService.generic(this.$translate.instant('JS_SPACE.CONFIRM.LINK_TOOL'), undefined, {
        cancelText : 'GENERAL_SPACE.BUTTON.CONTACTS_NO',
        confirmText: 'GENERAL_SPACE.BUTTON.CONTACTS_YES',
        size       : 'lg'
      })
      .then(() => {
        this.router.stateService.go('app.branches.contacts');
      })
      .catch(() => {
        this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.CONFIRM_SYNC'))
        .then(() => this.quickbooksService.sync())
        .then(() => {
          this.messagesService.show(this.$translate.instant('JS_SPACE.QUICKBOOKS.SYNC_CONFIRM'), 'success');
        })
        .catch(() => {
          this.apiService.get('qbo/ignore_sync_before')
          .then(() => this.quickbooksService.sync())
          .then(() => {
            this.messagesService.show(this.$translate.instant('JS_SPACE.QUICKBOOKS.SYNC_CONFIRM'), 'success');
          });
        });
      });
    }
    else {
      this.quickbooksService.sync()
      .then(() => {
        this.messagesService.show(this.$translate.instant('JS_SPACE.QUICKBOOKS.SYNC_CONFIRM'), 'success');
      });
    }
  };

  syncXero () : void {
    if (this.companyService.isLinkedToBranch() && this.companyService.isSharedAccounting() && !this.companyService.company.last_xero_sync_down) {
      this.confirmService.generic(this.$translate.instant('JS_SPACE.CONFIRM.LINK_TOOL'), undefined, {
        cancelText : 'GENERAL_SPACE.BUTTON.CONTACTS_NO',
        confirmText: 'GENERAL_SPACE.BUTTON.CONTACTS_YES',
        size       : 'lg'
      })
      .then(() => this.router.stateService.go('app.branches.contacts'))
      .catch(() => {
        this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.CONFIRM_SYNC'))
        .then(() => {
          this.xeroService.sync()
          .then(( conflicts : number ) => {
            if (conflicts) {
              this.router.stateService.go('app.xero-contacts');
            }
            else {
              this.messagesService.show(this.$translate.instant('JS_SPACE.XERO.SYNC_CONFIRM'), 'success');
            }
          });
        })
        .catch(() => {
          this.apiService.get('xero/ignore_sync_before')
          .then(() => {
            return this.xeroService.sync()
            .then(( conflicts : number ) => {
              if (conflicts) {
                this.router.stateService.go('app.xero-contacts');
              }
              else {
                this.messagesService.show(this.$translate.instant('JS_SPACE.XERO.SYNC_CONFIRM'), 'success');
              }
            });
          });
        });
      });
    }
    else {
      this.xeroService.sync()
      .then(( conflicts : number ) => {
        if (conflicts) {
          this.router.stateService.go('app.xero-contacts');
        }
        else {
          this.messagesService.show(this.$translate.instant('JS_SPACE.XERO.SYNC_CONFIRM'), 'success');
        }
      });
    }
  }

  viewSupportResources () : void {
    this.modalService.open(SupportResourcesComponent);
  }
}
