import { NgModule } from '@angular/core';

import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [
    FooterComponent
  ],
  entryComponents: [
    FooterComponent
  ]
})
export class FooterModule {}
