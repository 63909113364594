import 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';

import { capitalize }              from './core.filters.capitalize.ajs';
import { chargifyCurrency }        from './core.filters.chargify-currency.ajs';
import { cut }                     from './core.filters.cut.ajs';
import { dateTime }                from './core.filters.date-time.ajs';
import { globalCurrency }          from './core.filters.global-currency.ajs';
import { globalDate }              from './core.filters.global-date.ajs';
import { hours }                   from './core.filters.hours.ajs';
import { htmlToPlainText }         from './core.filters.html-to-plain-text.ajs';
import { mobilePhone }             from './core.filters.mobile.ajs';
import { month }                   from './core.filters.month.ajs';
import { months }                  from './core.filters.months.ajs';
import { phone }                   from './core.filters.phone.ajs';
import { pluralize }               from './core.filters.pluralize.ajs';
import { properCase }              from './core.filters.proper-case.ajs';
import { regex }                   from './core.filters.regex.ajs';
import { taxType }                 from './core.filters.tax-type-ajs';

import { CapitalizePipe }         from '../../shared/pipes/capitalize.pipe';
import { ChargifyCurrencyPipe }   from '../../shared/pipes/chargify-currency.pipe';
import { CutPipe }                from '../../shared/pipes/cut.pipe';
import { DateTimePipe }           from '../../shared/pipes/date-time.pipe';
import { GlobalCurrencyPipe }     from '../../shared/pipes/global-currency.pipe';
import { GlobalDatePipe }         from '../../shared/pipes/global-date.pipe';
import { HoursPipe }              from '../../shared/pipes/hours.pipe';
import { HtmlToPlainTextPipe }    from '../../shared/pipes/html-to-plain-text.pipe';
import { MobilePhonePipe }        from '../../shared/pipes/mobile.pipe';
import { MonthPipe }              from '../../shared/pipes/month.pipe';
import { MonthsPipe }             from '../../shared/pipes/months.pipe';
import { PhonePipe }              from '../../shared/pipes/phone.pipe';
import { PluralizePipe }          from '../../shared/pipes/pluralize.pipe';
import { ProperCasePipe }         from '../../shared/pipes/proper-case.pipe';
import { RegexPipe }              from '../../shared/pipes/regex.pipe';
import { TaxTypePipe }            from '../../shared/pipes/tax-type.pipe';

angular.module('app-lite.core.filters', [])
  .filter('capitalize', capitalize)
  .filter('chargifyCurrency', chargifyCurrency)
  .filter('cut', cut)
  .filter('dateTime', dateTime)
  .filter('globalCurrency', globalCurrency)
  .filter('globalDate', globalDate)
  .filter('hours', hours)
  .filter('htmlToPlainText', htmlToPlainText)
  .filter('mobilePhone', mobilePhone)
  .filter('month', month)
  .filter('months', months)
  .filter('phone', phone)
  .filter('pluralize', pluralize)
  .filter('properCase', properCase)
  .filter('regex', regex)
  .filter('taxType', taxType)
  .service('capitalizePipe',        downgradeInjectable(CapitalizePipe))
  .service('chargifyCurrencyPipe',  downgradeInjectable(ChargifyCurrencyPipe))
  .service('cutPipe',               downgradeInjectable(CutPipe))
  .service('dateTimePipe',          downgradeInjectable(DateTimePipe))
  .service('globalCurrencyPipe',    downgradeInjectable(GlobalCurrencyPipe))
  .service('globalDatePipe',        downgradeInjectable(GlobalDatePipe))
  .service('hoursPipe',             downgradeInjectable(HoursPipe))
  .service('htmlToPlainTextPipe',   downgradeInjectable(HtmlToPlainTextPipe))
  .service('mobilePhonePipe',       downgradeInjectable(MobilePhonePipe))
  .service('monthPipe',             downgradeInjectable(MonthPipe))
  .service('monthsPipe',            downgradeInjectable(MonthsPipe))
  .service('phonePipe',             downgradeInjectable(PhonePipe))
  .service('pluralizePipe',         downgradeInjectable(PluralizePipe))
  .service('properCasePipe',        downgradeInjectable(ProperCasePipe))
  .service('regexPipe',             downgradeInjectable(RegexPipe))
  .service('taxTypePipe',           downgradeInjectable(TaxTypePipe));
