import angular from 'angular';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { PartstechOrdersComponent } from './partstech.orders.component';
import { PartstechService }         from './partstech.service';

angular.module('app-lite.partstech', [])
  .directive('partstechOrders', downgradeComponent({ component : PartstechOrdersComponent }))
  .service('partstechApi', downgradeInjectable(PartstechService));
