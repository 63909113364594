import { NgModule } from '@angular/core';

import { SharedModule }     from '../shared/shared.module';
import { SidebarComponent } from './sidebar.component';
import { SidebarService }   from './sidebar.service';

@NgModule({
  declarations: [
    SidebarComponent
  ],
  entryComponents: [
    SidebarComponent
  ],
  exports: [
    SidebarComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    SidebarService
  ]
})
export class SidebarModule {}
