export function StockTakeQuickAddCtrl (
  $scope,
  $translate,
  messages,
  workshopAPI
) {

  $scope.loaded        = true;
  $scope.quantity      = '0';
  $scope.productSearch = {
    item_code: ''
  };

  $scope.save = function () {
    workshopAPI.patch('/stock_take', [
      {
        draft_quantity: $scope.quantity,
        id            : '',
        product_id    : $scope.product.id
      }
    ], 'stock_take')
    .then(() => {
      messages.show($translate.instant('STOCKTAKE_SPACE.PRODUCT_ADDED_TO_STOCKTAKE', { item_code: $scope.productSearch.item_code }), 'success');

      $scope.productSearch.item_code = '';
      $scope.product                 = null;
      $scope.quantity                = '0';
    });
  };

  $scope.findProduct = function () {
    workshopAPI.get('/stock_takes/find_single_item_for_stocktake', $scope.productSearch.item_code)
    .then(product => {
      $scope.product = product;
    })
    .catch(err => {
      messages.error(err);
    });
  };
};