import CreateCarLoanHtml from './loan-car-schedule.create.html';
import LoanVehicleHtml   from './templates/loan.vehicle.html';
import ReturnLoanCarHtml from './templates/return.loan.car.html';
import ReviewLoanCarHtml from './templates/review.loan.car.html';

export function LoanCarService (
  $uibModal,
  $q,
  workshopAPI
) {

  var LoanCar = function ( loanCar = {} ) {
    var self = this;

    this.loanCar = loanCar;

    this.crud = {
      save: () => self.loanCar.id ? 'patch' : 'post'
    };

    this.routes = {
      save: () => self.loanCar.id ? '/loan_car' : '/loan_cars'
    };

    this.init();

  };

  LoanCar.prototype.configureUpdate = function (loanCar) {
    var self = this;

    self.loanCar.start_date = loanCar.local_booking_start_time ? moment(loanCar.local_booking_start_time).format() : null;

    if (loanCar.local_booking_start_time) {
      self.loanCar.start_date = moment(loanCar.local_booking_start_time).format('DD/MM/YYYY');
    }

  };

  LoanCar.prototype.init = function () {
    var self = this;

    // Set the local_start_time and local_return_time
    // based off the booking's start and return times.
    if (self.loanCar.local_booking_start_time && self.loanCar.local_booking_end_time) {
      self.loanCar.start_time = moment(self.loanCar.local_booking_start_time, 'YYYY-MM-DDThh:mm:ssZ').format('hh:mm:ss A');
      self.loanCar.return_time = moment(self.loanCar.local_booking_end_time, 'YYYY-MM-DDThh:mm:ssZ').format('hh:mm:ss A');

      console.log(self.loanCar);

      // Set the local_start_date and local_return_date
      // based off the booking's start and return dates.
      self.loanCar.start_date = moment(self.loanCar.local_booking_start_time, 'YYYY-MM-DDThh:mm:ssZ').format('YYYY-MM-DD');
      self.loanCar.return_date = moment(self.loanCar.local_booking_end_time, 'YYYY-MM-DDThh:mm:ssZ').format('YYYY-MM-DD');
    } else {
      self.loanCar.start_time = moment(self.loanCar.start_time, 'HH:mm:ss').format('hh:mm:ss A')
      self.loanCar.return_time = moment(self.loanCar.return_time, 'HH:mm:ss').format('hh:mm:ss A')
    };
  };

  LoanCar.prototype.manageLoanVehicle = function ( id ) {
    return $uibModal.open({
      controller: 'LoanVehicleCtrl',
      resolve   : {
        loan_vehicle: () => id
          ? workshopAPI.get('/loan_vehicle', id)
          : null,
        status: () => workshopAPI.get('/system/loan_vehicle_status_types/', null, 'loanVehicleStatusTypes')
      },
      templateUrl: LoanVehicleHtml,
      windowClass: 'lg-modal'
    }).result;
  };

  LoanCar.prototype.modifyLoan = function () {
    var self = this

    return $uibModal.open({
      templateUrl: CreateCarLoanHtml,
      windowClass: 'x-lg-modal',
      controller: 'ModifyLoanCarCtrl',
      resolve: {
        status: [ function () {
          return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
        }],
        loan_car: function () {
          return self.loanCar;
        },
        loan_vehicle: function () {
          return self.loanCar.loan_vehicle_id ? workshopAPI.get('/loan_vehicle', self.loanCar.loan_vehicle_id) : [];
        },
        states: function () {
          return workshopAPI.get('/system/state_types/', null, 'states');
        },
        invoice: function () {
          return self.loanCar.invoice_id ? workshopAPI.get('/invoice', self.loanCar.invoice_id) : [];
        },
        event: function () {
          return self.loanCar.event_id ? workshopAPI.get('/event', self.loanCar.event_id) : [];
        },
        customer: function () {
          return self.loanCar.customer_id ? workshopAPI.get('/customer', self.loanCar.customer_id) : [];
        },
        available_vehicles: function () {
          return workshopAPI.get('/loan_vehicle/available_vehicles_list', [self.loanCar.local_booking_start_time, self.loanCar.local_booking_end_time, self.loanCar.id]);
        }
      }
    }).result;

  };

  LoanCar.prototype.returnLoan = function () {
    var self = this

    return $uibModal.open({
      templateUrl: ReturnLoanCarHtml,
      windowClass: 'x-lg-modal',
      controller: 'ReturnLoanCarCtrl',
      resolve: {
        status: [ function () {
          return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
        }],
        loan_vehicle: function () {
          return self.loanCar.loan_vehicle_id ? workshopAPI.get('/loan_vehicle', self.loanCar.loan_vehicle_id) : [];
        },
        states: function () {
          return workshopAPI.get('/system/state_types/', null, 'states');
        },
        loan: function () {
          return self.loanCar;
        },
        serviceAdviserBefore: function () {
          var service_adviser_id = self.loanCar.confirmed_by_before
          return service_adviser_id ? workshopAPI.get('/service_adviser', self.loanCar.confirmed_by_before) : []
        },
        serviceAdviserReturn: function () {
          var service_adviser_id = self.loanCar.confirmed_by_return
          return service_adviser_id ? workshopAPI.get('/service_adviser', self.loanCar.confirmed_by_return) : []
        },
        invoice: function () {
          return self.loanCar.invoice_id ? workshopAPI.get('/invoice', self.loanCar.invoice_id) : [];
        },
        event: function () {
          return self.loanCar.event_id ? workshopAPI.get('/event', self.loanCar.event_id) : [];
        },
        customer: function () {
          return self.loanCar.customer_id ? workshopAPI.get('/customer', self.loanCar.customer_id) : [];
        }

      }

    }).result;
  };

  LoanCar.prototype.reviewLoan = function () {
    var self = this

    return $uibModal.open({
      templateUrl: ReviewLoanCarHtml,
      windowClass: 'x-lg-modal',
      controller: 'ReviewLoanCarCtrl',
      resolve: {
        status: [ function () {
          return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
        }],
        loan_vehicle: function () {
          return self.loanCar.loan_vehicle_id ? workshopAPI.get('/loan_vehicle', self.loanCar.loan_vehicle_id) : [];
        },
        states: function () {
          return workshopAPI.get('/system/state_types/', null, 'states');
        },
        loan: function () {
          return self.loanCar;
        },
        serviceAdviserBefore: function () {
          var service_adviser_id = self.loanCar.confirmed_by_before
          return service_adviser_id ? workshopAPI.get('/service_adviser', self.loanCar.confirmed_by_before) : []
        },
        serviceAdviserReturn: function () {
          var service_adviser_id = self.loanCar.confirmed_by_return
          return service_adviser_id ? workshopAPI.get('/service_adviser', self.loanCar.confirmed_by_return) : []
        },
        invoice: function () {
          return self.loanCar.invoice_id ? workshopAPI.get('/invoice', self.loanCar.invoice_id) : [];
        },
        event: function () {
          return self.loanCar.event_id ? workshopAPI.get('/event', self.loanCar.event_id) : [];
        },
        customer: function () {
          return self.loanCar.customer_id ? workshopAPI.get('/customer', self.loanCar.customer_id) : [];
        }

      }

    }).result;
  };

  LoanCar.prototype.save = function ( loanCar ) {
    const self = this;

    self.loanCar.local_booking_start_time = self.loanCar.start_time
      ? self.loanCar.start_date + moment(self.loanCar.start_time, 'hh:mm:ss A').format('THH:mm:ssZ')
      : null;

    self.loanCar.local_booking_end_time = self.loanCar.return_time
      ? self.loanCar.return_date + moment(self.loanCar.return_time, 'hh:mm:ss A').format('THH:mm:ssZ')
      : null;

    const params = {
      loan_car: self.loanCar,
      ...( self.loanCar.loan_status === 'A' && { update_duration: true } )
    };

    return workshopAPI[ self.crud.save() ](self.routes.save(), params);
  };

  return LoanCar;
}