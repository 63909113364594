import { UrlService } from '@uirouter/core';

import getCountryCodeAlpha3 from 'country-iso-2-to-3';

import { ApiModel }                        from '../api/models/api.model';
import { ApiServiceAjs }                   from '../api/api.service.ajs';
import { SessionServiceAjs }               from '../sessions/session.service.ajs';
import { UserModel }                       from '../users/models/user';
import { XeroChartOfAccountsModel }        from './models/xero.chart-of-accounts' ;
import { XeroClientCredentials }           from './models/xero.client-credentials';
import { XeroCompanyModel }                from '../../app-auth/xero/models/xero.company';
import { XeroOrganizationModel }           from '../../app-auth/xero/models/xero.organization';
import { XeroSettingsModel }               from './models/xero.settings';
import { XeroTaxRateModel }                from './models/xero.tax-rate';
import { XeroTokensModel }                 from '../../app-auth/xero/models/xero.tokens';
import { XeroTrackingCategories }          from './models/xero.tracking-categories';
import { XeroTrackingCategoryWithOptions } from './models/xero.tracking-categories.with-options';

import SyncXeroHtml  from './xero.sync.html';
import XeroErrorHtml from './xero.error.html';

export class XeroServiceAjs {
  loginUrl = 'https://login.xero.com/identity/connect/authorize';

  constructor (
    private $q           : ng.IQService,
    private $uibModal    : ng.ui.bootstrap.IModalService,
    private $urlService  : UrlService,
    private API          : ApiModel,
    private sessionObjAPI: SessionServiceAjs,
    private workshopAPI  : ApiServiceAjs
  ) {}

  accounts () : ng.IPromise<XeroChartOfAccountsModel> {
    return this.workshopAPI.get('/xero/chart_of_accounts');
  }

  addXeroTax ( tax : string ) : ng.IPromise<any> {
    return this.workshopAPI.post('/xero/adjust_tax_rates', tax, 'tax_rate');
  }

  checkExistingIntegrations () : ng.IPromise<string> {
    return this.workshopAPI.get('/company/accounting_ids_exist/xero');
  }

  clearID () : ng.IPromise<boolean> {
    return this.workshopAPI.post('/company/clear_accounting_contact_ids', { accounting_integration: 'xero', confirmation: 'CLEAR IDS' });
  }

  didUserVerificationSucceed ( queryParams : any = this.$urlService.search() ) : boolean {
    return queryParams.xero_request_successful && queryParams.xero_request_successful === 'true';
  }

  disconnectUser ( user : UserModel ) : ng.IPromise<boolean> {
    return this.workshopAPI.get('xero/disconnect_user');
  }

  getAuthenticationUrl () : string {
    return `${ this.API.url }/xero/new_from_sign_up`;
  }

  getClientCredentials () : ng.IPromise<XeroClientCredentials> {
    return this.workshopAPI.get('/xero/get_xero_client_credentials');
  }

  getSignupTokens ( queryParams : any = this.$urlService.search() ) : ng.IPromise<XeroTokensModel> {
    return this.workshopAPI.get('/xero/exchange_code_for_tokens', null, null, {
      params: queryParams
    });
  }

  getUserForSignup ( tokens : XeroTokensModel ) : ng.IPromise<UserModel> {
    return this.workshopAPI.post('xero/organisation', {
      access_token: tokens.access_token
    })
    .then(( organization : XeroOrganizationModel ) => {
      return {
        company_name: organization.Name,
        country     : getCountryCodeAlpha3(organization.CountryCode).toLowerCase()
      };
    });
  }

  getSignupUrlFromCountry ( country : string, queryParams : any = this.$urlService.search() ) : ng.IPromise<string> {
    const tag = '/login.html#/xero-signup';

    const queryString = Object.keys(queryParams)
    .map(( key : string ) => `${ key }=${ encodeURIComponent(queryParams[key]) }`)
    .join('&');

    switch (country) {
      case 'can':
        return this.$q.when(`${ this.API.domains.can }${ tag }?${queryString}`);
      case 'gbr':
        return this.$q.when(`${ this.API.domains.gbr }${ tag }?${queryString}`);
      case 'usa':
        return this.$q.when(`${ this.API.domains.usa }${ tag }?${queryString}`);
      default:
        return this.$q.when(`${ this.API.domains.aus }${ tag }?${queryString}`);
    }
  }

  getTaxRates () : ng.IPromise<Array<XeroTaxRateModel>> {
    return this.settings()
    .then(( settings : XeroSettingsModel ) => angular.isArray(settings.tax_rates)
      ? settings.tax_rates
      : []
    );
  }

 getTrackingCategories () : ng.IPromise<Array<XeroTrackingCategories>> {
    return this.workshopAPI.get('xero/get_tracking_categories');
  }

  getTrackingCategoryWithOptions () : ng.IPromise<XeroTrackingCategoryWithOptions> {
    return this.workshopAPI.get('xero/get_tracking_options');
  }

  getVerifyUserUrl () : string {
    return `${ this.API.url }/xero/new/user_validation`;
  }

  grantAccess ( company : XeroCompanyModel, tokens : XeroTokensModel ) : ng.IPromise<string> {
    const params : any = angular.extend(company, tokens);

    return this.workshopAPI.post('/xero/grant_access_from_sign_up', params)
    .then(( result : any ) => result.login_code);
  }

  isNewUser ( queryParams : any = this.$urlService.search() ) : boolean {
    return !!queryParams.new_user;
  }

  isUserVerified ( user : UserModel ) : boolean {
    return !!user.xero_id;
  }

  login ( company : XeroCompanyModel, code : string ) : ng.IPromise<string> {
    return this.workshopAPI.post('/sessions/xero_from_sign_up', {
      company_id: company.company_id,
      login_code: code
    })
    .then(( session : any ) => this.sessionObjAPI.setSessionAndValidate(session))
    .then(( rights : any ) => this.$q.when('/workshop.html#/xero_settings?new_user'));
  }

  needsAuthentication ( queryParams : any = this.$urlService.search() ) : boolean {
    return !queryParams.code;
  }

  settings () : ng.IPromise<XeroSettingsModel> {
    return this.workshopAPI.get('xero/settings', null, null, {
      timeout: 30000
    });
  }

  shareAccount ( shareAccountFlag : boolean ) : ng.IPromise<any> {
    return this.workshopAPI.get('/company/share_accounting', [ shareAccountFlag ? 't' : 'f' ]);
  }

  showVerificationError ( queryParams : any = this.$urlService.search() ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller : 'XeroErrorCtrl',
      templateUrl: XeroErrorHtml,
      resolve    : {
        error: () => {
          return this.workshopAPI.get('xero/retrieve_error', queryParams.code)
          .then(( response : any ) => response.error_text);
        }
      }
    }).result;
  }

  signup ( user : UserModel ) : ng.IPromise<XeroCompanyModel> {
    return this.workshopAPI.post('/subscriptions/xero_setup', user);
  }

  sync () : ng.IPromise<number> {
    return this.$uibModal.open({
      controller : 'SyncXeroCtrl',
      templateUrl: SyncXeroHtml,
      windowClass: 'flip-container'
    }).result
    .then(( conflicts : string ) => parseInt(conflicts));
  }

  wasUserVerificationAttempted ( queryParams : any = this.$urlService.search() ) : boolean {
    return !!queryParams.xero_request_successful;
  }
}