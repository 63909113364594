import { Component, Inject } from '@angular/core';
import { NgbActiveModal }    from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector   : 'wor-support-resources',
  template: require('./support.resources.component.html')
})
export class SupportResourcesComponent {
  constructor (
    @Inject('$translate')
    public $translate : ng.translate.ITranslateService,
    public activeModal: NgbActiveModal
  ) {}
}
