import { LoanCarScheduleActionsComponent } from './loan.car.actions.component';

export function LoanCarDiarySimplifiedCtrl (
  $rootScope,
  $scope,
  $translate,
  LoanCarSchedule,
  sessionObjAPI,
  workshopAPI
) {
  $scope.loading = true;
  $scope.company = sessionObjAPI.company();

  if ($scope.company.company_variable_fields.plate_number_name) {
    $scope.regoLabel = $scope.company.company_variable_fields.plate_number_name;
  }
  else {
    $scope.regoLabel = sessionObjAPI.countrySettings().rego_label;
  };

  $scope.LoanCarSchedule = new LoanCarSchedule($rootScope.settings, $rootScope.groups);

  $scope.tableSettings = {
    columns: [
      {
        field : 'loan_date',
        header: {
          text:$translate.instant('GENERAL_SPACE.FIELD.DATE')
        },
        pipe  : 'globalDate'
      },
      {
        field : 'contact_name',
        header: {
          text: $translate.instant('CUSTOMER_SPACE.CUSTOMER')
        }
      },
      {
        field : 'booking_start_time',
        header: {
          text: $translate.instant('LOAN_CAR_SPACE.START')
        },
        pipe    : 'dateTime',
        pipeArgs: ['HH:mm']
      },
      {
        field : 'booking_end_time',
        header: {
          text: $translate.instant('LOAN_CAR_SPACE.END')
        },
        pipe    : 'dateTime',
        pipeArgs: ['HH:mm']
      },
      {
        field : 'plate_number',
        header: {
          text: $scope.regoLabel
        }
      },
      {
        field : 'event_number',
        header: {
          text: $translate.instant('LOAN_CAR_SPACE.BOOK_NO')
        }
      },
      {
        field : 'job_card_number',
        header: {
          text: $translate.instant('LOAN_CAR_SPACE.INV_NO')
        }
      },
      {
        field : 'loan_status',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        pipe  : 'loanStatus'
      },
      {
        classes  : 'text-center',
        component: {
          bindings: {
            outputs: {
              onModifyLoan: event => $scope.onModifyLoan(event),
              onStartLoan : event => $scope.onStartLoan(event),
              onReturnLoan: event => $scope.onReturnLoan(event),
              onReviewLoan: event => $scope.onReviewLoan(event)
            }
          },
          enabled: true,
          factory: LoanCarScheduleActionsComponent,
        },
        sortable: false,
        width: 200
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_LOANS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : 5,
      pageSizes : [ 5, 10, 20, 50 ],
      queryCount: () => workshopAPI.get('/loan_cars/loan_car_booking_list_count')
    },
    sorting: {
      direction: 'asc',
      enabled  : true,
      field    : 'loan_date'
    },
  };

  $scope.getGridLoans = ( params ) => {
    return workshopAPI.get('/loan_cars/loan_car_booking_list', [
      params.pageSize,
      params.offset,
      params.field,
      params.direction,
    ])
    .then(loans => {
      $scope.loans = loans;
      $scope.loading = false;
      return loans;
    });
  };

  $scope.init = function () {
    $scope.getGridLoans({
      pageSize : 6,
      offset   : 0,
      direction: 'asc',
      field    : 'loan_date'
    });
  };

  $scope.onModifyLoan = function (event) {
    $rootScope.$broadcast('pg-tooltip-hide');
    $scope.LoanCarSchedule.goToLoan(event.id, 'modifyLoan');
  };

  $scope.onStartLoan = function (event) {
    $rootScope.$broadcast('pg-tooltip-hide');
    $scope.LoanCarSchedule.goToLoan(event.id, 'startLoan');
  };

  $scope.onReturnLoan = function (event) {
    $rootScope.$broadcast('pg-tooltip-hide');
    $scope.LoanCarSchedule.goToLoan(event.id, 'returnLoan');
  };

  $scope.onReviewLoan = function (event) {
    $rootScope.$broadcast('pg-tooltip-hide');
    $scope.LoanCarSchedule.goToLoan(event.id, 'reviewLoan');
  };

  $scope.init();
}