import { ApiServiceAjs } from '../api/api.service.ajs';

import SendRemindersHtml from './reminders.send.html'

export class RemindersServiceAjs {
  constructor (
    private $uibModal: ng.ui.bootstrap.IModalService
  ) {}

  send () : ng.IPromise<any> {
    return this.$uibModal.open({
      controller : 'SendRemindersCtrl',
      templateUrl: SendRemindersHtml,
      windowClass: 'lg-modal flip-container service-reminders',
      resolve    : {
        defaults: ( workshopAPI : ApiServiceAjs ) => workshopAPI.get('/system/service_reminder_defaults/')
      }
    }).result;
  };
}
