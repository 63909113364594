import AdvisersSearchResultsHtml from '../advisers/templates/advisers.search.results.html';

export function ReturnLoanCarCtrl (
  $q, 
  $rootScope,
  $scope, 
  $state,
  $timeout,   
  $translate, 
  $uibModalInstance, 
  confirm, 
  customer,
  event,
  invoice,
  loan,
  loan_vehicle,
  LoanCar,
  messages, 
  serviceAdviserBefore, 
  serviceAdviserReturn,
  sessionObjAPI,
  states, 
  status,
  streamSearch,
  workshopAPI
) {
  
  $scope.loanStatusTypes = status;
  $scope.loan_vehicle = loan_vehicle;
  $scope.states = states;
  $scope.loan_car = loan;
  $scope.invoice = invoice;
  $scope.event = event;
  $scope.customer = customer;
  $scope.company = sessionObjAPI.company();
  if ($scope.company.company_variable_fields.plate_number_name) {
    $scope.regoLabel = $scope.company.company_variable_fields.plate_number_name;
  }
  else {
    $scope.regoLabel = sessionObjAPI.countrySettings().rego_label;
  }
  ;
  $scope.LoanCar = new LoanCar($scope.loan_car);
  $scope.serviceAdviserBefore = serviceAdviserBefore;
  $scope.serviceAdviserReturn = serviceAdviserReturn;
  if ($scope.LoanCar.invoice_id) {
    workshopAPI.get('/invoice', $scope.LoanCar.invoice_id).then(function (invoice) {
      $scope.invoice = invoice;
    });
  }
  ;
  if ($scope.LoanCar.event_id) {
    workshopAPI.get('/event', $scope.LoanCar.event_id).then(function (event) {
      $scope.event = event;
    });
  }
  ;
  $scope.returnLoan = function () {
    $scope.LoanCar.save().then(function () {
      workshopAPI.get('/loan_car/return_loan_car', $scope.LoanCar.loanCar.id);
    }).then(function (loan) {
      $uibModalInstance.close();
      $state.reload();
      messages.show($translate.instant('LOAN_CAR_SPACE.LOAN_CAR_RETURNED'), 'success');
    }, function (err) {
      return $q.reject(err);
    });
  };
  $scope.serviceAdviserSearch = {
    templateUrl: AdvisersSearchResultsHtml,
    list: [],
    value: {
      id: serviceAdviserReturn ? $scope.serviceAdviserReturn.id : '',
      display_name: serviceAdviserReturn ? $scope.serviceAdviserReturn.display_name : '',
    },
    query: function (value) {
      return workshopAPI.get('/service_advisers/list', [ value, 0, '*' ]);
    },
    select: function (adviser) {
      $scope.serviceAdviserReturn.id = adviser.id;
      $scope.serviceAdviserReturn.display_name = adviser.display_name;
      $scope.loan_car.confirmed_by_return = $scope.serviceAdviserReturn.id;
    },
    format: function (adviser) {
      return adviser ? adviser.display_name : '';
    }
  };
}
