import { Injector, NgModule } from '@angular/core';

import { SharedModule }   from '../shared/shared.module';
import { XeroServiceAjs } from './xero.service.ajs';

@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : XeroServiceAjs,
      useFactory: ( $injector : Injector ) => $injector.get('xeroApi')
    }
  ]
})
export class XeroModule {}
