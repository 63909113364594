export function StaticLookupLiteCtrl ($scope, items, options) {
  $scope.items         = items;
  $scope.options       = options;
  $scope.selectedItems = [];

  $scope.tableSettings = {
    columns: $scope.options.columns,
    rows   : {
      select: {
        enabled: true
      }
    }
  };

  $scope.choose = function () {
    $scope.$close($scope.selectedItems[0]);
  };

  $scope.handleRowSelection = function ( row ) {
    $scope.selectedItems = $scope.items.filter(item => item.selected);
  };
}
