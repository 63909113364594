import { NgModule } from '@angular/core';

import { GlobalSearchModule } from '../global-search/global-search.module';
import { HeaderComponent }    from './header.component';
import { SharedModule }       from '../shared/shared.module';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  entryComponents: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    GlobalSearchModule,
    SharedModule
  ]
})
export class HeaderModule {}
