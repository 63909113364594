import { Injectable } from '@angular/core';

import { ApiServiceAjs } from './api.service.ajs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor (
    private apiServiceAjs: ApiServiceAjs
  ) {}

  delete ( url : string, id ?: string ) : angular.IPromise<any> {
    return this.apiServiceAjs.delete( url, id );
  }

  encode ( param : any, encodeFor = 'rails' ) : string {
    return this.apiServiceAjs.encode( param, encodeFor);
  }

  get ( url : string, params ?: any, cacheObj ?: string, config ?: any ) : angular.IPromise<any> {
    return this.apiServiceAjs.get( url, params, cacheObj, config );
  }

  getEndpoint () : string {
    return this.apiServiceAjs.API.url;
  }

  patch (url : string, data : any, name ?: string, cleanup ?: string, settings ?: any) : angular.IPromise<any> {
    return this.apiServiceAjs.patch( url, data, name, cleanup, settings );
  }

  post (url : string, data : any, name ?: string, cleanup ?: string, settings ?: any) : angular.IPromise<any> {
    return this.apiServiceAjs.post( url, data, name, cleanup, settings );
  }

  stream ( url : string, params ?: any ) : angular.IPromise<any> {
    return this.apiServiceAjs.stream( url, params );
  }

}