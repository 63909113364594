import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loanStatus',
})
export class LoanCarScheduleStatusPipe implements PipeTransform {

  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService
  ) {}

  transform( value : string ) {
    const typeMap = {
      P: this.$translate.instant('LOAN_STATUS_TYPES.PENDING'),
      A: this.$translate.instant('LOAN_STATUS_TYPES.ACTIVE'),
      C: this.$translate.instant('LOAN_STATUS_TYPES.RETURNED'),
      X: this.$translate.instant('LOAN_STATUS_TYPES.CANCELLED')
    };

    return typeMap[value] || this.$translate.instant('STATUS_TYPES.OPEN');
  }
}