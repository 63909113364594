import { Injector, NgModule } from '@angular/core';

import { LoanCarService }                  from './loan-car-schedule.service';
import { LoanCarScheduleActionsComponent } from './loan.car.actions.component';
import { LoanCarScheduleStatusPipe }       from './loan-car-schedule.status.pipe';
import { SharedModule }                    from '../shared/shared.module';

@NgModule({
  declarations: [
    LoanCarScheduleActionsComponent,
    LoanCarScheduleStatusPipe
  ],
  entryComponents: [
    LoanCarScheduleActionsComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : LoanCarService,
      useFactory: ( $injector : Injector ) => $injector.get('LoanCar')
    },

    LoanCarScheduleStatusPipe,

    // Pipes must also be provided via string
    // if they are going to be used in a table
    // column. This allows the injector to be
    // able to load a pipe dynamically in the
    // TableCellComponent.
    {
      provide : 'loanStatus',
      useClass: LoanCarScheduleStatusPipe
    }
  ]
})
export class LoanCarScheduleModule {}