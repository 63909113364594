import { Component, EventEmitter, Inject, Input, Output}    from '@angular/core';

@Component({
  template: require('./vehicle.history.actions.component.html')
})
export class VehicleHistoryActionsComponent {
  @Input() record: any;

  @Output() onCopyClick  : EventEmitter<any> = new EventEmitter();
  @Output() onEditClick  : EventEmitter<any> = new EventEmitter();
  @Output() onPrintClick : EventEmitter<any> = new EventEmitter();

  constructor (
    @Inject('$translate')
    public $translate: ng.translate.ITranslateService
  ) {}
}