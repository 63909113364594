import { NgModule } from '@angular/core';

import { GlobalSearchComponent } from './global-search.component';
import { SharedModule }          from '../shared/shared.module';

@NgModule({
  declarations: [
    GlobalSearchComponent
  ],
  entryComponents: [
    GlobalSearchComponent
  ],
  exports: [
    GlobalSearchComponent
  ],
  imports: [
    SharedModule
  ]
})
export class GlobalSearchModule {}
