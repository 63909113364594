import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { ApiService }                from '../api/api.service';
import { CompanyMessagesModel }      from '../companies/models/company.messages';
import { EmailParamsModel }          from '../messages/models/messages.send-email.params';
import { GlobalSearchAnimation }     from './global-search.animation';
import { GlobalSearchCategoryModel } from './typings/global-search.result';
import { LoanCarService }            from '../loan-car-schedule/loan-car-schedule.service';
import { MessagesServiceAjs }        from '../messages/messages.service.ajs';
import { MessengerService }          from '../messages/messenger.service.ajs';
import { SessionServiceAjs }         from '../sessions/session.service.ajs';
import { SidebarService }            from '../sidebar/sidebar.service';
import { SmsParamsModel }            from '../messages/models/messages.send-sms.params';
import { TypeaheadControlComponent } from '../shared/controls/typeahead-control/typeahead-control.component';

@Component({
  animations : [ GlobalSearchAnimation ],
  selector   : 'wor-global-search',
  styles: [ require('./global-search.component.scss') ],
  template: require('./global-search.component.html')
})
export class GlobalSearchComponent implements OnInit {
  public isCollapsed = true;

  delay = 1000;
  query = '';

  companyMessages: CompanyMessagesModel;
  companyService : any;
  loanCarService : any;
  results        : Array<GlobalSearchCategoryModel>;
  serverTimeout  : number;
  stream         : EventSource;

  @ViewChild('searchControl') searchControl : TypeaheadControlComponent;

  @ViewChildren(NgbDropdown) actionDropdowns: QueryList<NgbDropdown>;

  constructor (
    @Inject('$rootScope')
    private $rootScope      : any,
    @Inject('$translate')
    public  $translate      : ng.translate.ITranslateService,
    private apiService      : ApiService,
    private elementRef      : ElementRef<any>,
    private LoanCar         : LoanCarService,
    private messagesService : MessagesServiceAjs,
    private messengerService: MessengerService,
    public  sessionService  : SessionServiceAjs,
    private sidebarService  : SidebarService
  ) {
    this.loanCarService = new this.LoanCar();
  }

  ngOnInit () : void {
    this.companyService = this.$rootScope.Company;

    this.companyService.getDefaultMessages()
    .then(companyMessages => {
      this.companyMessages = companyMessages;
    });
  }

  @HostListener('document:click', ['$event'])
  _handleOutsideClick ( event : Event ) {
    if ( !this.isCollapsed && !this.elementRef.nativeElement.contains(event.target) ) {
      this.clearSearch(event);
    }
  }

  clearSearch ( event ?: Event ) : void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.isCollapsed = true;
    this.results     = [];
    this.query       = '';

    this.sidebarService.reset();
  }

  /**
   * Since the dropdown menu gets appended to the body, we
   * want to make sure the mouse hasn't moved into the related
   * dropdown menu. We check this by checking the index and the
   * category. If the category doesn't match or if the index doesn't
   * match, close the dropdown.
   */
  closeActionMenu ( dropdown : NgbDropdown, event ?: any ) : void {
    if (event) {
      const fromElementCategory = event.fromElement.dataset.category;
      const fromElementIndex    = event.fromElement.dataset.index;
      const toElementCategory   = event.toElement.dataset.category;
      const toElementIndex      = event.toElement.dataset.index;

      if ( fromElementCategory !== toElementCategory || fromElementIndex !== toElementIndex ) {
        dropdown.close();
      }
    }
    else {
      dropdown.close();
    }
  }

  doesCustomerHaveContact ( item : any ) : boolean {
    return item.contact_first
      || item.contact_last
      || item.contact_phone;
  }

  doesVendorHaveContact ( item : any ) : boolean {
    return item.contact1_first
      || item.contact1_last
      || item.contact1_phone;
  }

  formatCategory ( data : Array<any> ) : GlobalSearchCategoryModel {
    const header = data.filter(( item : any ) => item.category === 'headers')[0];
    const items  = data.filter(( item : any ) => item.category === header.table_name);

    return {
      category: header.table_name,
      items,
      title   : header.title
    };
  }

  formatter = ( item : any ) => {
    return this.query;
  }

  getTotalItems ( results : Array<GlobalSearchCategoryModel> ) : number {
    const items = [];

    results.forEach(result => {
      items.push(result.items);
    });

    return items.length;
  }

  openActionMenu ( dropdown : NgbDropdown ) : void {
    this.actionDropdowns.toArray().forEach(dropdown => dropdown.close());

    dropdown.open();
  }

  openSearch () : void {
    this.isCollapsed = false;

    this.searchControl.controlRef.nativeElement.focus();
  }

  /**
   * This needs to be an inline function to that "this" is
   * maintained when called from typeahead control.
   */
  search = ( typed : string ) : ng.IPromise<Array<any>> => {
    return new Promise(( resolve, reject ) => {
      this.results = [];

      if ( !typed || typed.length < 2 ) {
        return resolve([]);
      }

      if ( this.serverTimeout ) {
        clearTimeout(this.serverTimeout);

        this.results = [];

        if ( this.stream ) {
          this.stream.close();
        }
      }

      typed = this.apiService.encode(typed, 'get');

      this.serverTimeout = window.setTimeout(() => {
        const url = `${ this.apiService.getEndpoint() }/searches/${ typed }/all`;

        this.stream = new EventSource(url, { withCredentials: true });

        this.stream.addEventListener('message', ( event : MessageEvent ) => {
          this.serverTimeout = null;

          const category = this.formatCategory(JSON.parse(event.data));

          if ( !category.items.length ) {
            this.stream.close();

            return resolve(this.results);
          }

          this.results.push(category);

          if ( this.results && this.getTotalItems(this.results) >= 15 ) {
            this.stream.close();

            return resolve(this.results);
          }
        });

        this.stream.addEventListener('error', () => {
          this.sessionService.validate()
          .catch(() => {
            sessionStorage.error_msg = this.$translate.instant(
              'JS_SPACE.MESSAGES.INVALID_SESSION'
            );

            window.location.assign('/login.html');
          });

          this.stream.close();

          return resolve(this.results);
        });
      }, this.delay);
    });
  }

  sendEmail ( emailParams : EmailParamsModel, event : Event ) : void {
    this.clearSearch(event);

    this.messengerService
    .email({
      body         : emailParams.body,
      email_address: emailParams.email,
      name         : emailParams.name
    })
    .then(() => {
      this.messagesService.show(
        this.$translate.instant('JS_SPACE.MESSAGES.MESSAGE_SENT'),
        'success'
      );
    });
  }

  sendSms ( smsParams : SmsParamsModel, event : Event ) : void {
    this.clearSearch(event);

    this.messengerService
    .sms({
      name   : smsParams.name,
      send_to: smsParams.sendTo,
    })
    .then(() => {
      this.messagesService.show(
        this.$translate.instant('JS_SPACE.MESSAGES.MESSAGE_SENT'),
        'success'
      );
    });
  }

  viewLoanVehicle ( loanVehicle : any ) : void {
    this.loanCarService.manageLoanVehicle(loanVehicle.id);
  }
}
