import angular from 'angular';

import { AttachmentModel }    from './models/attachment';
import { AttachmentsService } from './attachments.service.ajs';

interface EmailAttachmentsScope extends ng.IScope {
  attachments: Array<AttachmentModel>;
  isPdf      : ( attachment : AttachmentModel ) => boolean;
  select     : ( attachment : AttachmentModel ) => void;
  selected   : Array<string>;
  selectAll  : () => void;
};

export function EmailAttachmentsCtrl (
  $scope        : EmailAttachmentsScope,
  attachmentsApi: AttachmentsService
) {
  $scope.isPdf = function ( attachment : AttachmentModel ) : boolean {
    return attachmentsApi.isPdf(attachment);
  };

  $scope.select = function ( attachment : AttachmentModel ) : void {
    attachment.selected = !attachment.selected;
  };

  $scope.selectAll = function () : void {
    angular.forEach($scope.attachments, attachment => {
      attachment.selected = !($scope.selected.length === $scope.attachments.length);
    });
  };

  $scope.$watch('attachments', ( attachments : Array<AttachmentModel> ) => {
    $scope.selected = attachments
    .filter(attachment => attachment.selected)
    .map(attachment => attachment.id);
  }, true);
};
