import angular from 'angular';

import 'bootstrap-legacy';

import '../vendor/chosen/chosen.min';
import '../vendor/placeholders/placeholders.min';
import '../vendor/sprintf/sprintf';

import './core/core.module';
import './login/login.module.ajs';
import './quickbooks/quickbooks.module';
import './two-factor-auth/two-factor-auth.module.ajs';
import './xero/xero.module.ajs';

import { AuthVerifyCtrl }    from './auth.verify';
import { ResetPasswordCtrl } from './auth.reset-password';
import { SignUpCtrl }        from './auth.sign-up';

angular.module('app', [
  'app.core',
  'app.login',
  'app.quickbooks',
  'app.two-factor-auth',
  'app.xero'
])
.controller('AuthVerifyCtrl', AuthVerifyCtrl)
.controller('ResetPasswordCtrl', ResetPasswordCtrl)
.controller('signUpCtrl', SignUpCtrl);
