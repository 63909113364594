import { Injectable }      from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private _reset = new Subject<boolean>();
  public reset$  = this._reset.asObservable();

  reset () : void {
    this._reset.next(true);
  }
}
