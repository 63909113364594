import { Component, Inject, Input }    from '@angular/core';

@Component({
  template: require('./vehicle.invoice-items.row.expand.component.html')
})
export class VehicleInvoiceItemsRowExpandComponent {
  @Input() record: any;

  constructor (
    @Inject('$translate')
    public $translate: ng.translate.ITranslateService,
  ) {}
}