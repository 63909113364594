import { Component, Inject, Input, OnInit }       from '@angular/core';
import { ControlContainer, NgForm }               from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { DateAdapter }          from './date.adapter';
import { DateParserFormatter }  from './date.parser';
import { DateService }          from './date.service';
import { TextControlComponent } from '../text-control/text-control.component';

@Component({
  selector   : 'wor-date-control',
  styles: [require('./date-control.component.scss')],
  template: require('./date-control.component.html'),

  providers: [
    {
      provide : NgbDateAdapter,
      useClass: DateAdapter
    },
    {
      provide : NgbDateParserFormatter,
      useClass: DateParserFormatter
    }
  ],

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class DateControlComponent extends TextControlComponent implements OnInit {
  @Input() icon      = 'calendar';
  @Input() iconTheme = 'workshop';

  _disabled: boolean;
  _required: boolean;
  yearOnly = false;

  constructor (
    @Inject('$translate')
    public $translate  : ng.translate.ITranslateService,
    private dateService: DateService
  ) {
    super($translate);
  }

  ngOnInit(): void {
    if (this.model && !this.dateService.containsDelimiter(this.model)) {
      this.yearOnly = true;
    }
  }
};
