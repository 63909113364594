import { CommonModule } from '@angular/common';
import { FormsModule  } from '@angular/forms';
import { NgModule     } from '@angular/core';

import { ApiModule                  } from './api/api.module';
import { AzureSsoModule             } from './azure-sso/azure-sso.module';
import { BillerModule               } from './biller/biller.module';
import { BusinessIntelligenceModule } from './business-intelligence/business-intelligence.module';
import { ChargifyModule             } from './chargify/chargify.module';
import { CompaniesModule            } from './companies/companies.module'
import { CoreModule                 } from './core/core.module';
import { CustomerPaymentsModule     } from './customer-payments/customer-payments.module';
import { CustomersModule            } from './customers/customers.module';
import { EmailVerificationModule    } from './email-verification/email-verification.module';
import { FooterModule               } from './footer/footer.module';
import { GlobalSearchModule         } from './global-search/global-search.module';
import { GsfModule                  } from './gsf/gsf.module';
import { HeaderModule               } from './header/header.module';
import { InvoicesModule             } from './invoices/invoices.module';
import { LoanCarScheduleModule      } from './loan-car-schedule/loan-car-schedule.module';
import { MessagesModule             } from './messages/messages.module';
import { MyobModule                 } from './myob/myob.module';
import { OnlinePaymentsModule       } from './online-payments/online-payments.module';
import { PartstechModule            } from './partstech/partstech.module';
import { ProductsModule             } from './products/products.module';
import { QuickbooksModule           } from './quickbooks/quickbooks.module';
import { RecpatchaModule            } from './recaptcha/recaptcha.module';
import { RemindersModule            } from './reminders/reminders.module';
import { SessionsModule             } from './sessions/sessions.module';
import { SharedModule               } from './shared/shared.module';
import { SidebarModule              } from './sidebar/sidebar.module';
import { StockTakeModule            } from './stock-take/stock-take.module';
import { StripeModule               } from './stripe/stripe.module';
import { SupportModule              } from './support/support.module';
import { TableModule                } from './table/table.module';
import { TillpaymentsModule         } from './tillpayments/tillpayments.module';
import { TireconnectModule          } from './tireconnect/tireconnect.module';
import { TransactionCenterModule    } from './transaction-center/transaction-center.module';
import { UnitsModule                } from './units/units.module';
import { UsersModule                } from './users/users.module';
import { VehiclesModule             } from './vehicles/vehicles.module';
import { VendorsModule              } from './vendors/vendors.module';
import { WorkshopPayModule          } from './workshoppay/workshoppay.module';
import { XeroModule                 } from './xero/xero.module';

@NgModule({
  imports: [

    // Vendor modules.
    CommonModule,
    FormsModule,

    // Feature modules.
    ApiModule,
    AzureSsoModule,
    BillerModule,
    BusinessIntelligenceModule,
    ChargifyModule,
    CompaniesModule,
    CoreModule,
    CustomerPaymentsModule,
    CustomersModule,
    EmailVerificationModule,
    FooterModule,
    GlobalSearchModule,
    GsfModule,
    HeaderModule,
    InvoicesModule,
    LoanCarScheduleModule,
    MessagesModule,
    MyobModule,
    OnlinePaymentsModule,
    PartstechModule,
    ProductsModule,
    QuickbooksModule,
    RecpatchaModule,
    RemindersModule,
    SessionsModule,
    SharedModule,
    SidebarModule,
    StockTakeModule,
    StripeModule,
    SupportModule,
    TableModule,
    TillpaymentsModule,
    TireconnectModule,
    TransactionCenterModule,
    UnitsModule,
    UsersModule,
    VehiclesModule,
    VendorsModule,
    WorkshopPayModule,
    XeroModule
  ]
})
export class AppLiteModule {}
