import { ApiServiceAjs } from '../api/api.service.ajs';
import { TableService }  from '../table/table.service';

import LoanCarActivateHtml from './loan.car.activate.html';
import LoanCarScheduleHtml from './loan-car-schedule.html';
import LoanVehiclesHtml    from './loan.vehicles.html';

export function LoanCarScheduleStates (
   $stateProvider : ng.ui.IStateProvider
) {
  $stateProvider
  .state({
    controller: 'LoanCarScheduleCtrl',
    name      : 'app.loan-car',
    params    : {
      customer_id: { squash: true, value: null }
    },
    templateUrl: LoanCarScheduleHtml,
    url        : '/loanCar/:customer_id?view&group_index&date&loanVehicle&loanVehicleId&viewSingleLoanVehicle',
    resolve    : {
      customer: ( $stateParams : ng.ui.IStateParamsService, workshopAPI : ApiServiceAjs ) : ng.IPromise<any> | Array<any> =>
        $stateParams.customer_id
          ? workshopAPI.get('/customer', $stateParams.customer_id)
          : [],
      groups  : ( $stateParams : ng.ui.IStateParamsService, workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => workshopAPI.get('/system/loan_vehicle_grouping_for_filter', $stateParams.view === 'multiColAgendaDay' ? '5' : '10'),
      settings: ( workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => workshopAPI.get('/company'),
      states  : ( workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => workshopAPI.get('/system/state_types', null, 'states')
    }
  })
  .state({
    controller : 'LoanCarActivateCtrl',
    name       : 'app.loan-car-activate',
    templateUrl: LoanCarActivateHtml,
    url        : '/loan_car/activate',
    resolve    : {
      settings: ( workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => workshopAPI.get('/company')
    }
  })
  .state({
    controller : 'LoanVehiclesCtrl',
    name       : 'app.loan-vehicles',
    templateUrl: LoanVehiclesHtml,
    url        : '/loanVehicles',
    resolve    : {
      loan_vehicles: ( tableApi : TableService, workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => {
        const cache = tableApi.getCacheForTable('loanVehicles');

        return workshopAPI.stream('/filters', [
          '*',
          'loan_vehicles',
          0,
          cache.paging.pageSize   || 10,
          cache.sorting.field     || 'plate_number',
          cache.sorting.direction || 'asc',
          false
        ]);
      }
    }
  });
}