export class BigDecimalService {
  get ( number ?: string | number, scale ?: number, rounding = BigDecimal.ROUND_HALF_UP ) : any {
    const convertedNumber = this.isNumber(number)
      ? new BigDecimal(number.toString())
      : BigDecimal.ZERO;

    return scale
      ? convertedNumber.setScale(scale, rounding)
      : convertedNumber;
  }

  isNumber ( number : any ) : boolean {
    return !( number === undefined || number === false || number === null || number === '' || isNaN(+number) );
  }
}
