import CreateCarLoanHtml from './loan-car-schedule.create.html';

export function LoanCarScheduleCtrl (
  $compile,
  $uibModal,
  $rootScope,
  $scope,
  $state,
  $stateParams,
  $transitions,
  $translate,
  $urlService,
  Company,
  customer,
  groups,
  LoanCar,
  LoanCarSchedule,
  messages,
  settings,
  states,
  uiCalendarConfig,
  workshopAPI
) {

  $scope.loaded       = true;
  $scope.simpleView   = false;

  $scope.LoanCarSchedule = new LoanCarSchedule(settings, groups);
  $scope.Company = new Company(settings);

  $scope.customer = customer;
  $scope.loan_vehicle_id = ''; //'8729c852-3cc2-11e8-9bb4-7b733f3c7511'
  $scope.states = states;

  $rootScope.settings = settings;
  $rootScope.groups = groups;

  $scope.catchDraggedLoan = function (loan) {
    $scope.LoanCarSchedule
      .updateScheduledLoan(loan)
      .then(() => messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.LOAN'), 'success'))
      .catch(err => messages.error(err))
      .finally(() => $scope.LoanCarSchedule.view.name === 'multiColAgendaDay' ? $state.reload() : null);
  };


  $scope.createLoan = function () {
    return $uibModal.open({
      templateUrl: CreateCarLoanHtml,
      windowClass: 'x-lg-modal',
      controller: 'CreateLoanCarCtrl',
      resolve: {
        status: [ function () {
          return workshopAPI.get('/system/loan_status_types/', null, 'loanStatusTypes');
        }],
        customer: function () {
          return $scope.customer;
        },
        loan_vehicle: function () {
          return $scope.loan_vehicle_id ? workshopAPI.get('/loan_vehicle', $scope.loan_vehicle_id) : [];
        },
        states: function () {
          return $scope.states ? $scope.states : [];
        }
    }
  }).result;

  };

  $scope.dayClick = function (date) {
    $scope.LoanCarSchedule.dayClick(date);
  };

  $scope.loanRender = function (loan, element, view) {
    element.html($compile($scope.LoanCarSchedule.getLoanTemplate(loan))($scope));
  };

  $scope.getLoans = function (start, end, timezone, callback) {
    $scope.LoanCarSchedule.configureLoans(start, end, timezone, callback);
  };

  $scope.goToLoan = function (id, type) {
    $rootScope.$broadcast('pg-tooltip-hide');
    $scope.LoanCarSchedule.goToLoan(id, type);
  };

  $scope.view = function (loan) {
    $scope.LoanCarSchedule.viewLoan(loan);
    $rootScope.$broadcast('pg-tooltip-hide');
  };

  $scope.viewDate = function (index) {
    $scope.LoanCarSchedule.viewDate($scope.LoanCarSchedule.loans[index].date);
    $rootScope.$broadcast('pg-tooltip-hide');
  };

  $scope.viewRender = function (view) {
    $scope.LoanCarSchedule.viewRender(view);
  };

  $scope.calendar = {
    allDayDefault: false,
    allDaySlot: false,
    columnFormat: $scope.Company.company.language === 'en-us' ? 'ddd M/D': 'ddd D/M',
    contentHeight: 'auto',
    dayClick: $scope.dayClick,
    defaultDate: $stateParams.date || moment().format('YYYY-MM-DD'),
    defaultView: $stateParams.view || 'multiColAgendaDay',
    droppable: false,
    loanBackgroundColor: 'transparent',
    eventDrop: $scope.catchDraggedLoan,
    eventRender: $scope.loanRender,
    events: $scope.getLoans,
    loanTextColor: 'transparent',
    firstDay: 1,
    customButtons: {
      todayCustom: {
        text: $translate.instant('GENERAL_SPACE.BOOKING_DAYS.TODAY'),
        click: function () {
          $scope.LoanCarSchedule.buildRouteAndReload({
            date: moment().format('YYYY-MM-DD'),
            includeDate: true,
            loanVehicle: $stateParams.loanVehicle,
            loanVehicleId: $stateParams.loanVehicleId,
            viewSingleLoanVehicle: $stateParams.viewSingleLoanVehicle
          });
        }
      }
    },
    header:{
      left: 'title',
      center: '',
      right: 'multiColAgendaDay,todayCustom,prev,next'
    },
    lazyFetching: true,
    maxTime: $scope.LoanCarSchedule.shopCloses || '18:00:00',
    minTime: $scope.LoanCarSchedule.shopOpens || '08:00:00',
    slotDuration: '00:15:00',
    timeFormat: '',
    timezone: 'UTC',
    viewRender: $scope.viewRender,
    views: {
      multiColAgendaDay: {
        columnHeaders: $scope.LoanCarSchedule.loanVehicleGroup.headerColumns || $scope.LoanCarSchedule.loanVehicleGroup.plate_numbers,
        duration: { days: 1 },
        editable: true,
        formatDate: $scope.Company.company.language === 'en-us' ? 'mm/dd': 'dd/mm',
        numColumns: $scope.LoanCarSchedule.loanVehicleGroup.plate_numbers.length,
        type: 'multiColAgenda'
      }
    }
  };

  $transitions.onExit({
    exiting: 'app.loan-car'
  }, transition => {
    if ( transition.to().name !== 'app.loan-car' ) {
      $scope.LoanCarSchedule.clearStyles();
    }
  });

  // day view header columns provide links to view
  // specific vehicles. This works with those links
  // because they cannot escape angular markup. This feature is not enabled yet but this will allow for it to be in the future.
  $transitions.onRetain({ retained: 'app.loan-car' }, () => {
    if ($stateParams.view === 'multiColAgendaDay' && $stateParams.loanVehicleId) {
      $state.reload();
    }
  });

  $rootScope.$broadcast('pg-tooltip-hide');

  // Some users are reporting that the tooltip will
  // not always disappear, even across pages. This
  // will watch the current url and when changed, fire
  // a $broadcast to hide the tooltip.
  $scope.$watch(function(){
    return $urlService.path();
  }, function(value){
    $rootScope.$broadcast('pg-tooltip-hide');
  });
}
