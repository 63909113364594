import angular from 'angular';

import { CodeTypeModel }           from '../core/models/code-type';
import { InspectionTemplateModel } from './models/inspection.template';

interface InspectionTemplateScope extends ng.IScope {
  addGroup      : () => void;
  copy          : () => void;
  delete        : () => void;
  group         : CodeTypeModel;
  originalGroups: Array<CodeTypeModel>;
  save          : ( form: ng.IFormController ) => void;
  template      : any;
};


export function InspectionTemplateCtrl (
  $scope             : ng.IScope,
  $state             : ng.ui.IStateService,
  $translate         : ng.translate.ITranslateService,
  confirm            : any,
  groups             : Array<CodeTypeModel>,
  InspectionTemplate : any,
  messages           : any,
  modalApi           : any,
  template           : InspectionTemplateModel,
  types              : Array<CodeTypeModel>,
  workshopAPI        : any
) {
  var vm : InspectionTemplateScope = this;

  vm.originalGroups = angular.fromJson(angular.toJson(groups));
  vm.template       = new InspectionTemplate(template, groups, types);

  vm.addGroup = function () {
    vm.template.addGroup(vm.group);

    vm.group = undefined;
  };

  vm.copy = function () {
    modalApi.capture({
      capture: 'Name of Template',
      title  : 'Copy Inspection Template'
    })
    .then(name => vm.template.copy(name))
    .then(result => {
      vm.template = new InspectionTemplate(result, groups, types);

      messages.show('Inspection Template Copied.', 'success');
    })
    .catch(( err : any ) => messages.error(err));
  };

  vm.save = function () {
    vm.template.save()
    .then(( template : InspectionTemplateModel ) => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.TEMPLATE'), 'success');

      if (vm.template.id) {
        $state.reload();
      }

      else {
        $state.go('app.inspection-template', {
          id: template.id
        });
      }
    })
    .catch(( err : any ) => {
      messages.error(err);
    })
    .finally(() => {
      $state.reload();
    });
  };

  vm.delete = function () {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_TEMPLATE'))
    .then(() => workshopAPI.delete('/inspection_template', vm.template.id))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.TEMPLATE'), 'success');

      $state.go('app.inspection-templates')
    })
    .catch(( err : any ) => {
      messages.error(err);
    });
  };

  $scope.$watch(() => vm.template.groups.length, () => {
    var originalGroups = angular.fromJson(angular.toJson(vm.originalGroups));
    var groupCodes = [];

    angular.forEach(vm.template.groups, ( group : CodeTypeModel ) => {
      groupCodes.push(group.code);
    });

    vm.template.groupOptions = originalGroups.filter(( group : CodeTypeModel ) => groupCodes.indexOf(group.code) < 0);
  });

};