import { AzureSsoService } from '../azure-sso/azure-sso.service';
import { CodeTypeModel }   from '../core/models/code-type';
import { GroupModel }      from '../core/models/group';
import { UserModel }       from './models/user';

interface UserScope extends ng.IScope {
  block                          : ng.blockUI.BlockUIService;
  canResetTwoFactorAuth          : () => boolean;
  capricornUser                  : string;
  clear                          : ( field : string ) => void;
  currency                       : any;
  delete                         : () => void;
  form                           : ng.IFormController;
  groups                         : Array<GroupModel>;
  isBusinessIntelligenceAvailable: () => boolean;
  loaded                         : boolean;
  originalBiStatus               : boolean;
  reconnectToAzure               : () => void;
  resetTwoFactorAuth             : () => void;
  save                           : () => void;
  status_types                   : Array<CodeTypeModel>;
  user                           : UserModel;
  userQuote                      : any;
}

export function UserCtrl (
  $q                     : ng.IQService,
  $rootScope             : any,
  $scope                 : UserScope,
  $state                 : ng.ui.IStateService,
  $translate             : ng.translate.ITranslateService,
  azureSsoApi            : AzureSsoService,
  blockUI                : ng.blockUI.BlockUIService,
  businessIntelligenceApi: any,
  confirm                : any,
  messages               : any,
  sessionObjAPI          : any,
  user                   : UserModel,
  userQuote              : any,
  workshopAPI            : any
) {
  $scope.user = user.id
    ? user
    : {
      dashboard_privileges: 'L',
      state               : 'ACTIVE'
    };

  $scope.block            = blockUI.instances.get('userBlock');
  $scope.capricornUser    = sessionObjAPI.base().capricorn_user;
  $scope.currency         = sessionObjAPI.currency();
  $scope.loaded           = true;
  $scope.originalBiStatus = $scope.user.business_intelligence_user;
  $scope.userQuote        = userQuote;

  $scope.user.confirm_password = $scope.user.password;

  workshopAPI.get('/groups', ['*', '*'])
  .then(( groups : Array<GroupModel> ) => {
    $scope.groups = groups;
  });

  workshopAPI.get('/system/user_state_types')
  .then(( types : Array<CodeTypeModel> ) => {
    $scope.status_types = types;
  });

  $scope.canResetTwoFactorAuth = function () {
    return !!$scope.user.id
      && $rootScope.Company.isTwoFactorAuthEnabled();
  };

  $scope.clear = function (field) {
    $scope.user[field] = '';
  };

  $scope.delete = function() {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_USER'))
    .then(() => workshopAPI.delete('/user', $scope.user.id))
    .then(() => {
      $scope.form.$setPristine();

      $state.go('app.users');

      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.USER'), 'success');
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.isBusinessIntelligenceAvailable = function () {
    return businessIntelligenceApi.isAvailable();
  };

  $scope.reconnectToAzure = function () {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.RECONNECT_TO_AZURE'))
    .then(() => azureSsoApi.reconnectUser($scope.user))
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.resetTwoFactorAuth = function () {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.RESET_TFA'))
    .then(() => workshopAPI.post('/user/reset_two_factor_auth_validity', {
        id: $scope.user.id
    }))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.TFA_RESET'), 'success');

      $scope.form.$setPristine();

      $state.reload();
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.save = function () {
    $scope.block.start();

    workshopAPI[
      $scope.user.id
      ? 'patch'
      : 'post'
    ]($scope.user.id
      ? '/user'
      : '/users', $scope.user, 'user')
    .then(user => {
      $scope.user.id = user.id;

      return $scope.originalBiStatus !== $scope.user.business_intelligence_user
        ? businessIntelligenceApi.updateStatus($scope.user)
        : $q.when($scope.user);
    })
    .then(user => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.USER'), 'success');

      $scope.form.$setPristine();

      $state.go('app.user', {
        id: user.id
      }, { reload: true });
    })
    .catch(err => {
      messages.error(err);
    })
    .finally(() => {
      $scope.block.stop();
    });
  };
}