export function BuySmsCtrl (
  $uibModalInstance,
  $scope,
  $translate,
  blockUI,
  confirm,
  cost,
  count,
  messages,
  workshopAPI
) {
  $scope.block      = blockUI.instances.get('buySmsBlock');
  $scope.count      = count;
  $scope.total_cost = 0;

  $scope.sms_purchase = {
    agree: false,
    cost,
    quantity: 0
  };

  $scope.purchase = function () {
    $scope.block.start();

    workshopAPI.get('/sms_logs/purchase', [ $scope.sms_purchase.agree, $scope.sms_purchase.quantity ])
    .then(() => workshopAPI.get('/sms_logs/current_sms_count'))
    .then(count => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.PURCHASE_SUCCESSFUL'), 'success');

      $uibModalInstance.close(count);
    })
    .then(() => confirm.notify({
      message: $translate.instant('JS_SPACE.MESSAGES.THANK_YOU_PURCHASE_MESSAGE')
    }))
    .catch(err => {
      messages.error(err);
    })
    .finally(() => {
      $scope.block.stop();
    });
  };

  $scope.getCost = function() {
    if ($scope.sms_purchase.quantity < 500) {
      $scope.total_cost = $scope.sms_purchase.cost.high_sms_price * $scope.sms_purchase.quantity;
    }
    else if ($scope.sms_purchase.quantity >= 500 && $scope.sms_purchase.quantity < 5000) {
      $scope.total_cost = $scope.sms_purchase.cost.medium_sms_price * $scope.sms_purchase.quantity;
    }
    else {
      $scope.total_cost = $scope.sms_purchase.cost.low_sms_price * $scope.sms_purchase.quantity;
    };
  };
}