import ProductBranchStockHtml from './product.branch-stock.html';

export function ProductLiteService (
  $q,
  $rootScope,
  $translate,
  $uibModal,
  bigDecimalApi,
  confirm,
  sessionObjAPI,
  workshopAPI
) {
  var Product = function () {
    this.company = sessionObjAPI.company();
  };

  Product.prototype.activate = function () {
    const self = this;

    return confirm.generic($translate.instant('JS_SPACE.CONFIRM.ACTIVATE_PRODUCT', { code: self.product.item_code })).then(() => {
      return workshopAPI.get('/products/reactivate_product', self.product.id);
    });
  };

  Product.prototype.calculateCostWithoutTax = function ( costIncludingTax, purchaseTaxRate ) {
    costIncludingTax = bigDecimalApi.get(costIncludingTax.toString(), 4);

    const taxRate = bigDecimalApi.get(purchaseTaxRate, 4).movePointLeft(2);
    const cost    = costIncludingTax.divide(BigDecimal.ONE.add(taxRate)).setScale(4, BigDecimal.prototype.ROUND_HALF_UP);

    return parseFloat(cost);
  };

  Product.prototype.isLinkedToHeadOffice = function (product = this.product) {
    return !!product.branch_link;
  };

  Product.prototype.calculateCostWithTax = function ( cost, purchaseTaxRate ) {
    cost = bigDecimalApi.get(cost.toString(), 4);

    const taxRate = bigDecimalApi.get(purchaseTaxRate, 4).movePointLeft(2);
    const tax     = taxRate.multiply(cost).setScale(4, BigDecimal.prototype.ROUND_HALF_UP);

    return parseFloat(cost.add(tax).toString());
  };

  Product.prototype.calculateQtyAvailable = function () {
    const self = this;

    var qtyAvailable;

    var qty_on_hand  = bigDecimalApi.get(self.product.quantity_on_hand ? self.product.quantity_on_hand.toString(): '0').setScale(2, BigDecimal.prototype.ROUND_HALF_UP);
    var qty_reserved = bigDecimalApi.get(self.product.qty_reserved ? self.product.qty_reserved.toString(): '0').setScale(2, BigDecimal.prototype.ROUND_HALF_UP);

    qtyAvailable = qty_on_hand.subtract(qty_reserved).setScale(2, BigDecimal.prototype.ROUND_HALF_UP);

    return parseFloat(qtyAvailable).toFixed(1);
  };

  Product.prototype.findInHeadOffice = function () {
    if (!$rootScope.Company.isLinkedToBranch()) {
      return $q.when(false);
    }

    return $rootScope.Company.isDealerMultibranchStore()
    .then(( result ) => {
      return result
        ? workshopAPI.post('/product/branch_find_on_dealer', this.product, 'product')
        .then(( product ) => {
          return product
            ? confirm.generic('This product exists in the master database. Would you like to use these details?')
              .then(() => $q.when(product))
            : $q.when(false);
        })
        : $q.when(false);
    });
  };

  Product.prototype.getBranchProducts = function() {
    const self = this;

    return workshopAPI.post('/product/branch_find', {
      item_code: self.product.item_code
    });
  };

  Product.prototype.getStockTransfers = function() {
    const self = this;

    return workshopAPI.get('/products/stock_transfer_log_list', self.product.id);
  };

  Product.prototype.isLabor = function () {
    const self = this;

    return self.product.product_type === 'J';
  };

  Product.prototype.pushDownPricingInfo = function() {
    const self = this;

    return workshopAPI.get('/product/branch_push_down', self.product.id);
  };

  Product.prototype.save = function (form) {
    const self = this;

    const url    = self.product.id ? '/product': '/products';
    const method = self.product.id ? 'patch': 'post';

    if (!self.isLabor() && !document.getElementsByName('vendor')[0].value) {
      self.product.vendor_id = null;
    }

    return self.validateItemCode(form)
    .then(isUnique => {
      return isUnique
        ? $q.when(true)
        : confirm.generic($translate.instant('JS_SPACE.MESSAGES.PRODUCT_ALREADY_EXISTS', { code: self.product.item_code }));
    })
    .then(() => workshopAPI[method](url, self.product, 'product'))
    .then(product => {
      self.product = product;

      return product;
    });
  };

  Product.prototype.validateItemCode = function (form) {
    if (form && form.item_code.$pristine) {
      return $q.when(true);
    }

    return workshopAPI.post('/product/verify_code_uniqueness', { item_code: this.product.item_code });
  };

  Product.prototype.viewBranchStock = function () {
    const self = this;

    return $uibModal.open({
      controller : 'ProductBranchStockLiteCtrl',
      templateUrl: ProductBranchStockHtml,
      resolve    : {
        products() {
          return self.getBranchProducts();
        }
      },
      windowClass: 'x-lg-modal'
    }).result;
  };

  return Product;
};
