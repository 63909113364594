import angular from 'angular';

import { CodeTypeModel }                from '../core/models/code-type';
import { InspectionTemplateGroupModel } from './models/inspection.template.group';
import { InspectionTemplateItemModel }  from './models/inspection.template.item';

export function InspectionTemplateGroupService () {
  const InspectionTemplateGroup = function ( group : InspectionTemplateGroupModel, types : Array<CodeTypeModel> ) {
    this.types         = types;
    this.ordering      = group.ordering;
    this.itemOrderings = [];
    this.itemsToDelete = [];
    this.code          = group.code;
    this.description   = group.description;
    this.items         = this.initializeItems(group.items);

    this.resetOrderings();
  };

  InspectionTemplateGroup.prototype.initializeItems = function ( items : Array<InspectionTemplateItemModel> ) : Array<InspectionTemplateItemModel> {
    const self = this;

    if (!items || !items.length) {
      return [
        {
          _destroy              : '0',
          comment               : null,
          description           : null,
          estimated_time_red    : null,
          estimated_time_yellow : null,
          group_ordering        : self.ordering,
          id                    : null,
          index                 : 0,
          inspection_group      : self.code,
          inspection_template_id: null,
          inspection_type       : null,
          ordering              : 1,
          product_id            : null,
          product_item_code     : null
        }
      ];
    }

    angular.forEach(items, (item, index) => {
      item.index = index;
    });

    return items;
  };

  InspectionTemplateGroup.prototype.resetOrderings = function () {
    const self = this;

    self.itemOrderings = [];

    angular.forEach(self.items, ( item : InspectionTemplateItemModel, index : number ) => {
     self.itemOrderings.push(index + 1);
    });
  };

  InspectionTemplateGroup.prototype.orderItems = function () {
    const self = this;

    self.items.sort(( itemA : InspectionTemplateItemModel, itemB : InspectionTemplateItemModel ) => itemA.ordering - itemB.ordering);

    angular.forEach(self.items, ( item : InspectionTemplateItemModel, index : number ) => {
      item.ordering = index + 1;
    });
  };

  InspectionTemplateGroup.prototype.removeItem = function ( item : InspectionTemplateItemModel ) {
    const self = this;

    var itemToDelete = self.items.splice((item.ordering - 1), 1)[0];

    itemToDelete._destroy = '1';

    self.itemsToDelete.push(itemToDelete);
    self.resetOrderings();
    self.orderItems();

    if (!self.items.length) {
      self.addItem();
    }
  };

  InspectionTemplateGroup.prototype.addItem = function () {
    const self = this;

    var newestForm = self.items.length ? self.items[self.items.length - 1].form : {};

    if (newestForm.$invalid) {
      newestForm.$setDirty();

      return;
    }

    self.items.push({
      group_ordering  : self.ordering,
      index           : self.items.length,
      inspection_group: self.code,
      ordering        : self.items.length + 1
    });

    self.itemOrderings.push(self.items.length);
  };

  InspectionTemplateGroup.prototype.reorderItem = function ( item : InspectionTemplateItemModel ) {
    const self = this;

    var matchedIndex  = 0;
    var filteredItems = self.items.filter(( iteratedItem : InspectionTemplateItemModel ) => iteratedItem.index !== item.index);

    for (let i = matchedIndex; i < filteredItems.length; i++) {
      filteredItems[i].ordering++;
    }

    filteredItems.push(item);

    self.items = filteredItems;

    self.orderItems();

  };

  return InspectionTemplateGroup;
};