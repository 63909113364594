import { ApiServiceAjs }      from '../api/api.service.ajs';
import { ApiModel }           from '../api/models/api.model';
import { MessagesServiceAjs } from '../messages/messages.service.ajs';
import { SessionServiceAjs }  from '../sessions/session.service.ajs';

export function SendRemindersCtrl (
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $q               : ng.IQService,
  $scope           : any,
  $window          : ng.IWindowService,
  API              : ApiModel,
  defaults         : any,
  messages         : MessagesServiceAjs,
  sessionObjAPI    : SessionServiceAjs,
  templatedWording : any,
  workshopAPI      : ApiServiceAjs
) {

  $scope.reminders = {
    document_message: defaults.document_service_reminder,
    email_message   : defaults.email_service_reminder,
    end_date        : '',
    message_type    : 'default',
    sms_message     : defaults.sms_service_reminder,
    start_date      : defaults.start_date
  };

  $scope.company   = sessionObjAPI.company();
  $scope.regoLabel = $scope.company.company_variable_fields.plate_number_name || sessionObjAPI.countrySettings().rego_label;

  $scope.sendReminders = function ( asEmail : boolean ) {
    $scope.reminders.email_only = asEmail;

    workshopAPI.post('/system/send_service_reminders', $scope.reminders)
    .then(data => {
      $window.open(`${API.url}/reports/jasper_service_reminders.pdf`);

      return $q.when(data);
    })
    .then(data => {
      if (data.warning) {
        messages.show(data.message, 'warning');
      }
      else {
        $uibModalInstance.close();

        messages.show(data.message, 'success');
      }
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.buySMS = function () {
    workshopAPI.get('/sms_logs/purchase', [true, $scope.warning.sms_needed])
    .then(data => {
      $scope.sendReminders();
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.checkAvailSMS = function (form) {
    if ($scope.reminders.message_type !== ('email' || 'document')) {
      workshopAPI.get('/system/sms_sufficient_for_service_reminders', [$scope.reminders.start_date, $scope.reminders.end_date])
      .then(data => {
        if (data.sufficient_sms) {
          $scope.sendReminders();
        }
        else {
          $scope.warning = data;

          $('.modal-content').addClass('flip');
        }
      })
      .catch(err => {
        messages.error(err);
      });
    }
    else {
      $scope.sendReminders();
    }
  };

  $scope.smsLengthWarning = function () {
    if ($scope.reminders.sms_message.length <= 150) {
      return 'text-success';
    }
    else if ($scope.reminders.sms_message.length < 160) {
      return 'text-warning';
    }
    else {
      return 'text-danger';
    }
  };

  $scope.addTemplates = function () {
    templatedWording('document').then(templates => {
      angular.forEach(templates, (template, idx) => {
        if ($scope.reminders.document_message) {
          $scope.reminders.document_message = $scope.reminders.document_message + '\n\n' + template.wording;
        }
        else {
          $scope.reminders.document_message = template.wording;
        }
      });
    });
  };
}
