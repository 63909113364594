export function LoanCarActivateCtrl (
	$scope,
	$window,
	Company,
	settings
) {

  $scope.settings = settings;
  $scope.Company = new Company($scope.settings);

  $scope.subscriptionUpgrade = function () {
    return $window.location.href = '#/subscription_type_upgrade';
  };
}