import { ApiServiceAjs } from '../../app-lite/api/api.service.ajs';

import XeroCountryHtml from './xero.country.html';
import XeroSignupHtml  from './xero.signup.html';

export function XeroStates (
  $stateProvider: ng.ui.IStateProvider
) {
  $stateProvider
  .state({
    controller  : 'XeroCountryCtrl',
    controllerAs: '$ctrl',
    name        : 'app.xero-country',
    resolve     : {
      countries: ( workshopAPI : ApiServiceAjs ) => {
        return workshopAPI.get('/system/country_types', null, null, {
          bypass: true
        });
      }
    },
    templateUrl: XeroCountryHtml,
    url        : '/xero-country'
  })
  .state({
    controller    : 'XeroSignupCtrl',
    controllerAs  : '$ctrl',
    name          : 'app.xero-signup',
    resolve       : {
      countries: ( workshopAPI : ApiServiceAjs ) => {
        return workshopAPI.get('/system/country_types', null, null, {
          bypass: true
        });
      }
    },
    templateUrl: XeroSignupHtml,
    url        : '/xero-signup?code'
  });
}