import { Pipe, PipeTransform } from '@angular/core';
import { NgPluralizeService }  from 'ng-pluralize';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
  constructor (
    private pluralizeService: NgPluralizeService
  ) {}

  transform( value : string ) : string {
    this.pluralizeService.addPluralRule('Tech', 'Techs');
    this.pluralizeService.addPluralRule('Mech', 'Mechs');

    return this.pluralizeService.pluralize(value);
  }
}