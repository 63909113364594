import { ApiModel }                              from '../api/models/api.model';
import { HotkeyMapModel }                        from '../core/directives/hotkey/models/hotkey.map';
import { InvoiceItemModel }                      from '../invoices/models/invoice.item';
import { InvoiceModel }                          from '../invoices/models/invoice';
import { VehicleHistoryActionsComponent }        from './vehicle.history.actions.component';
import { VehicleHistoryRowExpandComponent }      from './vehicle.history.row.expand.component';
import { VehicleHistoryService }                 from './vehicle.history.service.ajs';
import { VehicleInvoiceItemsRowExpandComponent } from './vehicle.invoice-items.row.expand.component';
import { VehicleModel }                          from './models/vehicle';

interface VehicleHistoryScope extends ng.IScope {
  block                     : ng.blockUI.BlockUIService;
  clearSearch               : () => void;
  copyInvoice               : ( invoice : InvoiceModel, event : Event ) => void;
  currentIndex              : number;
  currentOffset             : number;
  filters                   : any;
  flipped                   : boolean;
  getCount                  : () => ng.IPromise<number>;
  getInvoice                : ( value : number ) => void;
  getInvoiceItems           : ( invoice ?: InvoiceModel ) => ng.IPromise<Array<InvoiceItemModel>>;
  getInvoiceItemsOnQuery    : ( params : any ) => ng.IPromise<Array<InvoiceItemModel>>;
  getInvoices               : ( params : any ) => ng.IPromise<Array<InvoiceModel>>;
  handleHotkey              : ( key : string ) => any;
  hotkeyMap                 : HotkeyMapModel;
  invoice                   : InvoiceModel;
  invoiceItems              : Array<InvoiceItemModel>;
  invoices                  : Array<InvoiceModel>;
  isFirstInvoice            : () => boolean;
  isInvoiceItemRowExpandable: ( row : any ) => boolean;
  isLastInvoice             : () => boolean;
  itemsTableSettings        : any;
  next                      : number;
  onRowDoubleClick          : ( event : any ) => void;
  paging                    : any;
  previous                  : number;
  printInvoice              : ( invoice : InvoiceModel, event : Event ) => void;
  resetInvoices             : () => ng.IPromise<Array<InvoiceModel>>;
  search                    : ( resetCurrentPage : boolean ) => void;
  select                    : ( invoice : InvoiceModel ) => void;
  sorting                   : any;
  tableSettings             : any;
  totalInvoiceCount         : number;
  updating                  : boolean;
  vehicle                   : VehicleModel;
  viewInvoices              : () => void;
  viewInvoice               : ( invoice : InvoiceModel, event ?: Event ) => void;
  viewInvoiceItems          : ( invoice : InvoiceModel ) => void;
}

export function VehicleHistoryCtrl (
  $q               : ng.IQService,
  $scope           : VehicleHistoryScope,
  $state           : ng.ui.IStateService,
  $timeout         : ng.ITimeoutService,
  $translate       : ng.translate.ITranslateService,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $window          : ng.IWindowService,
  API              : ApiModel,
  blockUI          : ng.blockUI.BlockUIService,
  invoices         : Array<InvoiceModel>,
  vehicle          : VehicleModel,
  vehicleHistoryApi: VehicleHistoryService
) {
  $scope.block             = blockUI.instances.get('vehicleHistoryBlock');
  $scope.currentIndex      = 0;
  $scope.currentOffset     = 0;
  $scope.flipped           = false;
  $scope.invoice           = null;
  $scope.invoices          = invoices;
  $scope.invoiceItems      = [];
  $scope.next              = 1;
  $scope.previous          = -1;
  $scope.totalInvoiceCount = 0;
  $scope.updating          = false;
  $scope.vehicle           = vehicle;

  $scope.itemsTableSettings = {
    broadcast: 'filterInvoiceItems',
    columns: [
      {
        field : 'item_code',
        header: {
          text: 'Item Code'
        }
      },
      {
        field   : 'description',
        header  : {
          text: 'Description'
        },
        sortable: false
      },
      {
        classes : ['text-center'],
        field   : 'quantity',
        header  : {
          text: 'Quantity'
        },
        pipe    : 'number',
        sortable: false
      },
      {
        classes : ['text-center'],
        field   : 'unit_price',
        header  : {
          text: 'Unit Price'
        },
        pipe    : 'globalCurrency',
        sortable: false
      },
      {
        classes : ['text-center'],
        field   : 'amount',
        header  : {
          text: 'Total'
        },
        pipe    : 'globalCurrency',
        sortable: false
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_ITEMS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : 5,
      pageSizes : [ 5, 10, 25, 50 ],
      queryCount: () => vehicleHistoryApi.getInvoiceItemsCount($scope.invoice)
    },
    rows: {
      expand: {
        enabled: true,
        factory: VehicleInvoiceItemsRowExpandComponent
      },
      highlight: {
        enabled: true
      }
    },
    sorting: {
      direction: 'asc',
      enabled  : true,
      field    : 'item_code'
    }
  };

  $scope.tableSettings = {
    broadcast: 'filterInvoices',
    columns: [
      {
        field : 'post_date',
        header: {
          text: 'Date'
        },
        pipe : 'globalDate'
      },
      {
        classes : ['text-center'],
        field   : 'invoice_number',
        header  : {
          text: 'Invoice Number'
        },
        sortable: false
      },
      {
        field   : 'invoice_type',
        header  : {
          text: 'Type'
        },
        pipe    : 'invoiceType',
        sortable: false
      },
      {
        classes : ['text-center'],
        field   : 'odometer',
        header  : {
          text: 'Odometer'
        },
        pipe    : 'number',
        sortable: false
      },
      {
        component: {
          bindings: {
            outputs: {
              onCopyClick : event => $scope.copyInvoice(event.record, event.event),
              onEditClick : event => $scope.viewInvoice(event.record , event.event),
              onPrintClick: event => $scope.printInvoice(event.record, event.event)
            }
          },
          enabled: true,
          factory: VehicleHistoryActionsComponent,
        },
        sortable: false,
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_HISTORY_FOUND')
    },
    filters : {
      value : null
    },
    pagination: {
      ...vehicleHistoryApi.tableSettings.pagination,
      queryCount: () => $scope.getCount()
    },
    rows: {
      expand: {
        enabled: true,
        factory: VehicleHistoryRowExpandComponent
      },
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: vehicleHistoryApi.tableSettings.sorting
  };

  $scope.clearSearch = function () {
    $scope.resetInvoices();
  };

  $scope.copyInvoice = function ( invoice : InvoiceModel, event : Event ) {
    vehicleHistoryApi.copyInvoice(invoice)
    .then(( invoice : InvoiceModel ) => $scope.viewInvoice(invoice));

    event.stopPropagation();
  };

  $scope.getCount = function () {
    if( $scope.tableSettings.filters.value ) {
      return vehicleHistoryApi.searchCount($scope.vehicle, $scope.tableSettings.filters.value).then( ( count : number ) => {
        $scope.totalInvoiceCount = count;
        return count;
      });
    } else {
      return vehicleHistoryApi.getCount($scope.vehicle).then( ( count : number ) => {
        $scope.totalInvoiceCount = count;
        return count;
      });
    }
  }

  $scope.getInvoice = function ( value : number ) {
    $scope.currentIndex += value;

    $scope.block.start();

    $scope.updating = true;

    if( $scope.tableSettings.filters.value ) {
      vehicleHistoryApi.search(
        $scope.vehicle,
        {
          offset   : $scope.currentIndex,
          pageSize : 1,
          field    : $scope.tableSettings.sorting.field,
          direction: $scope.tableSettings.sorting.direction
        },
        $scope.tableSettings.filters.value
        ).then( ( invoice : Array<InvoiceModel> ) => {
          $scope.invoice = invoice[0];

          return $scope.getInvoiceItems();
      }).then(( items : Array<InvoiceItemModel> ) => {
        $scope.invoiceItems = items;
      }).finally( () => {
        $scope.updating = false;

        $scope.block.stop();
      });
    } else {
      vehicleHistoryApi.getInvoices(
        $scope.vehicle,
        {
          offset   : $scope.currentIndex,
          pageSize : 1,
          field    : $scope.tableSettings.sorting.field,
          direction: $scope.tableSettings.sorting.direction
        }).then( ( invoice : Array<InvoiceModel> ) => {
          $scope.invoice = invoice[0];

          return $scope.getInvoiceItems();
      }).then(( items : Array<InvoiceItemModel> ) => {
        $scope.invoiceItems = items;
      }).finally( () => {
        $scope.updating = false;

        $scope.block.stop();
      });
    }
  }

  $scope.getInvoiceItems = function ( invoice = $scope.invoice ) {
    const params = {
      offset   : 0,
      pageSize : $scope.itemsTableSettings.pagination.pageSize,
      field    : $scope.itemsTableSettings.sorting.field,
      direction: $scope.itemsTableSettings.sorting.direction
    };

    return vehicleHistoryApi.getInvoiceItems( invoice, params );
  };

  $scope.getInvoiceItemsOnQuery = ( params ) => {
    return vehicleHistoryApi.getInvoiceItems( $scope.invoice, params );
  };

  $scope.getInvoices = ( params ) => {
    $scope.currentOffset = params.offset;

    if( $scope.tableSettings.filters.value ){
      return vehicleHistoryApi.search( $scope.vehicle, params, $scope.tableSettings.filters.value )
      .then(( invoices : Array<InvoiceModel> ) => {
        $scope.invoices = invoices;

        return invoices;
      });
    } else {
      return vehicleHistoryApi.getInvoices( $scope.vehicle, params )
      .then(( invoices : Array<InvoiceModel> ) => {
        $scope.invoices = invoices;

        return invoices;
      });
    }
  };

  $scope.handleHotkey = function ( key : string ) {
    if ($scope.block.isBlocking() || !$scope.flipped) {
      return;
    }

    switch (key) {
      case 'left':
        $scope.getInvoice($scope.previous);
        break;
      case 'right':
        $scope.getInvoice($scope.next);
        break;
    }
  };


  $scope.isFirstInvoice = function () {
    return $scope.currentIndex === 0;
  }

  $scope.isInvoiceItemRowExpandable = function ( row : any ) {
    return row.entity.note;
  };

  $scope.isLastInvoice = function () {
    return $scope.currentIndex === $scope.totalInvoiceCount - 1;
  }

  $scope.onRowDoubleClick = function (event) {
    $scope.viewInvoiceItems(event.row);
  };

  $scope.printInvoice = function ( invoice : InvoiceModel, event : Event ) {
    $window.open(`${ API.url }/reports/jasper_invoice/${ invoice.id }`, '_blank');

    event.stopPropagation();
  };

  $scope.resetInvoices = function () {
    $scope.updating = true;

    $scope.tableSettings.filters.value = null;

    return $scope.getInvoices({
      offset   : 0,
      pageSize : $scope.tableSettings.pagination.pageSize,
      field    : $scope.tableSettings.sorting.field,
      direction: $scope.tableSettings.sorting.direction
    })
    .then(( invoices : Array<InvoiceModel> ) => {
      $scope.invoices = [];

      $scope.updating = false;

      return $timeout(() => {
        $scope.invoices = invoices;

        return invoices;
      });

    });
  };

  $scope.search = function () {
    $scope.updating = true;

    $scope.getInvoices(
      {
        offset   : 0,
        pageSize : $scope.tableSettings.pagination.pageSize,
        field    : $scope.tableSettings.sorting.field,
        direction: $scope.tableSettings.sorting.direction
      }
    ).finally( () => {
      $scope.updating = false;
    });
  };

  $scope.select = ( row : any ) => {
    if (row.selected) {
      $scope.viewInvoiceItems(row.entity);

      row.selected = false;
    }

    row.selected = false;
  };

  $scope.viewInvoice = function ( invoice : InvoiceModel, event ?: Event ) {
    /**
     * this is also used when copying an invoice. a copied
     * invoice doesn't have and id so we need to check for that
     * so that null doesn't get passed in the url.
     */
    $state.go('app.invoice', {
      customer_id: invoice.customer_id,
      vehicle_id : invoice.vehicle_id,
      id         : invoice.id
    });

    $uibModalInstance.close();

    if (event) {
      event.stopPropagation();
    }
  };

  $scope.viewInvoiceItems = function ( invoice : InvoiceModel ) {
    $scope.block.start();

    $scope.updating = true;

    $scope.invoice                     = invoice;
    $scope.tableSettings.filters.value = null;

    const currentIndex = $scope.invoices.map(invoice => invoice.id).indexOf($scope.invoice.id);
    $scope.currentIndex = currentIndex + $scope.currentOffset;

    $scope.getInvoiceItems()
    .then(( items : Array<InvoiceItemModel> ) => {
      $scope.flipped = true;

      $scope.invoiceItems = items;

      $scope.$emit('flipModal');
    })
    .finally(() => {
      $scope.updating = false;

      $scope.block.stop();
    });
  };

  $scope.viewInvoices = function () {
    this.block.start();

    $scope.resetInvoices()
    .then(() => {
      $scope.invoice      = null;
      $scope.invoiceItems = [];
      $scope.flipped      = false;

      $scope.$emit('flipModal');
    })
    .finally(() => this.block.stop());

  };

  $scope.hotkeyMap = {
    left : $scope.handleHotkey,
    right: $scope.handleHotkey
  };
};