import { Component, Input } from "@angular/core";

@Component({
  selector   : '[worTooltip]',
  template: require('./tooltip.directive.html')
})
export class TooltipDirective {

  // Text to show in the tooltip.
  @Input('worTooltip') text : string;

  // Determines whether or not the tooltip is disabled.
  @Input('worTooltipDisabled') disabled : boolean;

  // The tooltip's placement, see possible values at
  // https://ng-bootstrap.github.io/#/guides/positioning#api.
  @Input('worTooltipPlacement') placement = 'top';
}