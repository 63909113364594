import { Injector, NgModule } from '@angular/core';

@NgModule({
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : 'stockTakeApi',
      useFactory: ( $injector : Injector ) => $injector.get('stockTakeApi')
    }
  ]
})
export class StockTakeModule {}
