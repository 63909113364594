export class PasswordControlCtrl implements ng.IComponentController {
  public label            ?: string;
  public minlength        ?: number;
  public model            : string;
  public mustMatch        : string;
  public name             : string;
  private originalValue   : string;
  public pattern          ?: RegExp;
  public placeholder      ?: string;
  public form             : ng.IFormController;
  public showHint         = false;
  private validatePattern = false;

  constructor (
    private $attrs   : ng.IAttributes,
    public $translate: ng.translate.ITranslateService
  ) {}

  $postLink () {
    if (this.$attrs.mustMatch) {
      const control = this.getControl();

      control.$parsers.unshift(( viewValue : string ) => {
        if ( !viewValue ) {
          control.$setValidity('match', true);

          return '';
        }

        if ( viewValue !== this.mustMatch ) {
          control.$setValidity('match', false);

          return undefined;
        }

        control.$setValidity('match', true);

        return viewValue;
      });
    }
  }

  $onInit () {
    this.minlength = this.shouldValidatePattern()
      ? 8
      : null;

    this.originalValue = this.model;

    this.pattern = this.shouldValidatePattern()
      ? /[A-Z]/
      : null;
  }

  getControl () : ng.INgModelController {
    return this.form
      ? this.form[this.name]
      : {};
  }

  getInvalidMessage () : string {
    const control = this.getControl();

    if ( this.$attrs.mustMatch && control.$error.match ) {
      return this.$translate.instant('GENERAL_SPACE.ERROR_MESSAGE.PASSWORDS_DO_NOT_MATCH');
    }

    return this.$translate.instant('GENERAL_SPACE.TITLE.PASSWORD_REQUIREMENTS');
  }

  isInvalidPattern () : boolean {
    const control = this.getControl();

    return (
      this.shouldValidatePattern()
      || this.$attrs.mustMatch
    )
      && control.$dirty
      && (
        control.$error.minlength
        || control.$error.pattern
        || control.$error.match
      );
  }

  shouldShowLabel () : boolean {
    const control = this.getControl();

    if (!this.label) {
      return false;
    }

    if ( control.$dirty && control.$error.required ) {
      return true;
    }

    return control.$pristine || control.$valid;
  }

  shouldValidatePattern () : boolean {
    return !!this.validatePattern
      && this.model !== this.originalValue;
  }
}
