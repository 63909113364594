export function ReviewLoanCarCtrl (
  $uibModalInstance, 
  $q, 
  $rootScope,
  $state, 
  $scope, 
  $timeout, 
  confirm,
  customer,
  event,
  invoice,
  loan,
  loan_vehicle,
  LoanCar,
  messages,
  serviceAdviserBefore, 
  serviceAdviserReturn,
  sessionObjAPI,
  states,
  status, 
  streamSearch,
  workshopAPI
) {
  $scope.loanStatusTypes = status;
  $scope.loan_vehicle = loan_vehicle;
  $scope.states = states;
  $scope.loan_car = loan;
  $scope.invoice = invoice;
  $scope.event = event;
  $scope.customer = customer;
  $scope.company = sessionObjAPI.company();
  if ($scope.company.company_variable_fields.plate_number_name) {
    $scope.regoLabel = $scope.company.company_variable_fields.plate_number_name;
  }
  else {
    $scope.regoLabel = sessionObjAPI.countrySettings().rego_label;
  }
  ;
  $scope.LoanCar = new LoanCar($scope.loan_car);
  $scope.serviceAdviserBefore = serviceAdviserBefore;
  $scope.serviceAdviserReturn = serviceAdviserReturn;
  if ($scope.LoanCar.invoice_id) {
    workshopAPI.get('/invoice', $scope.LoanCar.invoice_id).then(function (invoice) {
      $scope.invoice = invoice;
    });
  }
  ;
  if ($scope.LoanCar.event_id) {
    workshopAPI.get('/event', $scope.LoanCar.event_id).then(function (event) {
      $scope.event = event;
    });
  }
  ;
}
