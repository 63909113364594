import { Component, EventEmitter, Input, Output}    from '@angular/core';

@Component({
  template: require('./loan.car.actions.component.html')
})
export class LoanCarScheduleActionsComponent {
  @Input() record: any;

  @Output() onModifyLoan: EventEmitter<any> = new EventEmitter();
  @Output() onStartLoan : EventEmitter<any> = new EventEmitter();
  @Output() onReturnLoan: EventEmitter<any> = new EventEmitter();
  @Output() onReviewLoan: EventEmitter<any> = new EventEmitter();

  constructor (
  ) {}
}