import VendorSearchResultsHtml  from '../vendors/templates/vendors.search.results.html';

export function ProductsAdvancedSearchLiteCtrl (
  $q,
  $scope,
  $timeout,
  $translate,
  Company,
  confirm,
  currencyAPI,
  groups,
  IE,
  messages,
  product,
  Product,
  sessionObjAPI,
  streamSearch,
  types,
  workshopAPI
) {
  $scope.company                        = sessionObjAPI.company();
  $scope.currency                       = currencyAPI.info();
  $scope.form;
  $scope.flipped                        = false;
  $scope.product                        = product;
  $scope.productGroups                  = groups;
  $scope.productTypes                   = types;
  $scope.selectedItems                  = [];
  $scope.session                        = sessionObjAPI.base();
  $scope.vendorSearchResultsTemplateUrl = VendorSearchResultsHtml;
  $scope.wasHeadOfficeChecked           = false;

  $scope.Company = new Company($scope.company);
  $scope.IE      = IE();
  $scope.Product = new Product($scope.product);

  $scope.itemCodeValidatorOptions = {
    checkInitially: true
  };

  $scope.tableSettings = {
    columns: [
      {
        field : 'item_code',
        header: {
          text: $translate.instant('PRODUCT_SPACE.ITEM_CODE')
        }
      },
      {
        field : 'description',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.DESCRIPTION')
        }
      },
      {
        field : 'description2',
        header: {
          text: $translate.instant('INSPECTIONS_SPACE.DETAILS')
        }
      },
      {
        field : 'brand',
        header: {
          text: $translate.instant('PRODUCT_SPACE.BRAND')
        }
      },
      {
        field : 'quantity_on_hand',
        header: {
          text: $translate.instant('PRODUCT_SPACE.QTY')
        }
      },
      {
        pipe  : 'globalCurrency',
        field : 'retail_price',
        header: {
          text: $translate.instant('JS_SPACE.COLUMNS.PRICE')
        }
      },
      {
        pipe  : 'globalCurrency',
        field : 'cost',
        header: {
          text: $translate.instant('PRODUCT_SPACE.COST')
        }
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_PRODUCTS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : 5,
      pageSizes : [ 5, 10 ],
      queryCount: () => workshopAPI.get('/products/product_list_count', [ product.item_code ])
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
  };

  $scope.vendorSearch = {
    list  : [],
    choose: vendor => {
      $scope.product.vendor_id   = vendor.id;
      $scope.product.vendor_name = vendor.company_name;
    },
    format: vendor => vendor ? vendor.company_name: '',
    search: typed => streamSearch('vendors', typed).then(vendors => $scope.vendorSearch.list.concat(vendors)),
  };

  $scope.checkHeadOffice = function () {
    if ($scope.wasHeadOfficeChecked) {
      return;
    }

    $scope.Product.findInHeadOffice()
    .then(( product ) => {
      if (product) {
        $scope.product = product;

        $scope.Product = new Product($scope.product);

        if ($scope.product.vendor_id) {
          $scope.getVendor($scope.product.vendor_id);
        }

        $scope.wasHeadOfficeChecked = true;
      }
    })
    .finally(() => {
      $scope.wasHeadOfficeChecked = true;
    });
  };

  $scope.create = function (form) {
    /**
     * WOR-2733
     *
     * certain validations need to be made before a save can happen.
     * The item code needs to be checked for uniqueness and if the
     * user is linked to a head office, then the product needs to be
     * looked up in the head office. A lot of times a user will enter
     * an item code then hit save right away. This doesn't give the
     * blur effect time to complete so we check that as well as the
     * async validator flag. Keep calling save until those validations
     * are resolved.
     */
    if ( angular.isUndefined(form.item_code.duplicate) || !$scope.wasHeadOfficeChecked ) {
      return $timeout(() => $scope.create(form));
    }

    $scope.Product.save()
    .then(product => {
      $scope.Product.product = product;

      return $scope.session.platinum_or_higher && $scope.company.is_branch_owner ?
        confirm
          .save('Would you like to add this product to the other branches?')
          .then(updateProducts => angular.isDefined(updateProducts) && updateProducts ?
            $scope.Product.pushDownPricingInfo() : $q.when())
        : $q.when();
    })
    .then(()   => $scope.$close({ selection: $scope.Product.product, isNewProduct: true }))
    .catch(err => messages.error(err));
  };

  $scope.flipModal = function () {
    $scope.flipped = !$scope.flipped;

    $scope.$emit('flipModal');

    if ($scope.flipped && $scope.product.item_code) {
      $scope.checkHeadOffice();
    }
  };

  $scope.getCategory = function ( id ) {
    if ( id ) {
      workshopAPI.get('/product_category', id)
      .then(category => {
        $scope.product.product_category_name = category.name;

        $scope.category = {
          category_name    : category.name,
          id               : category.id,
          purchase_tax_rate: category.purchase_tax_rate
        };

        if ( $scope.product.cost ) {
          $scope.updateCostIncludingTax();
        }
      })
      .catch(err => messages.error(err));
    }
    else {
      $scope.product.product_category_name = '';

      $scope.category = {
        category_name    : '',
        id               : '',
        purchase_tax_rate: undefined
      };

      if ( $scope.product.cost ) {
        $scope.updateCostIncludingTax();
      }
    }
  };

  $scope.getProductGroup = function () {
    if ($scope.product.product_group_name) {
      workshopAPI.get('/product_group', $scope.product.product_group_name)
      .then(group => {
        $scope.product_group = group;

        if ($scope.product_group.length) {
          $scope.getCategory($scope.product_group[0].product_category_id);
        }
      });
    };
  };

  $scope.getProducts = ( params = { offset: 0, pageSize: 5 } ) => {
    return workshopAPI.get('/products/product_list', [
      product.item_code,
      params.offset,
      params.pageSize,
    ], null, { skipEncoding: true })
    .then(products => {
      $scope.products = products;

      if (!products.length && !$scope.flipped) {
        $scope.flipModal();
      }
      return products;
    });
  };

  $scope.getVendor = function (id) {
    workshopAPI.get('/vendor', id)
      .then(vendor => {
        $scope.vendorSearch.selected = {
          company_name: vendor.company_name,
          id          : vendor.id
        };

        $scope.product.vendor_name = vendor.company_name;
      }, err => messages.error(err));
  };

  $scope.onRowClick = function (event) {
    $scope.selectedItems = $scope.products.filter((row) => row.selected);
  };

  $scope.onRowDoubleClick = function (event) {
    $scope.selectedItems = [event.row];
    $scope.select();
  };

  $scope.select = function () {
    let isNewProduct = false;

    $scope.product = $scope.selectedItems[0];

    if ($scope.product.company_id != $scope.company.id) {
      workshopAPI
        .get('/products/copy_from_branch', $scope.product.id)
        .then(result => {
          $scope.product = result.product;
          isNewProduct   = true;

          $scope.$close({ selection: $scope.product, isNewProduct });
        });
    }
    else {
      $scope.$close({ selection: $scope.product, isNewProduct });
    }

  };

  $scope.updateCostExcludingTax = function () {
    $scope.product.cost = $scope.Product.calculateCostWithoutTax($scope.product.cost_including_tax, $scope.category.purchase_tax_rate);
  };

  $scope.updateCostIncludingTax = function () {
    $scope.product.cost_including_tax = $scope.Product.calculateCostWithTax($scope.product.cost, $scope.category.purchase_tax_rate);
  };

  $scope.validateItemCode = function ( item_code ) {
    const productApi = new Product({ item_code });

    return productApi.validateItemCode();
  };

  $scope.$watch(
    '[paging.pageSize, paging.currentPage]',
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        $scope.getProducts();
      }
    },
  true);

  /**
   * WOR-2733
   *
   * reset flag to check head office when item code has changed
   */
  $scope.$watch('product.item_code', ( newVal, oldVal ) => {
    if ( ( newVal && newVal !== oldVal )
      || ( newVal && oldVal && newVal.toLowerCase() !== oldVal.toLowerCase() )
    ) {
      $scope.wasHeadOfficeChecked = false;

      $scope.checkHeadOffice();
    }
  });

  $scope.$watch('form.item_code.duplicate', ( newVal, oldVal ) => {
    if (newVal !== oldVal) {
      $scope.form.item_code.$setDirty();
    }
  });

  $scope.getProducts();
};