import { ApiModel }                          from '../api/models/api.model';
import { ApiServiceAjs }                     from '../api/api.service.ajs';
import { InvoiceItemModel }                  from '../invoices/models/invoice.item';
import { InvoiceModel }                      from '../invoices/models/invoice';
import { SessionServiceAjs }                 from '../sessions/session.service.ajs';
import { VehicleHistoryReportSettingsModel } from './models/vehicle.history.report-settings';
import { VehicleModel }                      from './models/vehicle';

import VehicleHistoryHtml           from './vehicle.history.html';
import VehicleHistoryReportHtml     from './vehicle.history.report.html';
import WindowFlipHtml               from '../core/templates/window-flip.html';

export class VehicleHistoryService {
  $q        : ng.IQService;
  $uibModal : ng.ui.bootstrap.IModalService;
  $window   : ng.IWindowService;
  api       : ApiServiceAjs;
  API       : ApiModel;
  gridApi   : any;
  sessionApi: SessionServiceAjs;

  tableSettings = {
    pagination: {
      enabled  : true,
      pageSize : 5,
      pageSizes: [ 5, 10, 25, 50 ]
    },
    sorting: {
      direction: 'desc',
      enabled  : true,
      field    : 'post_date'
    }
  };

  constructor (
    $q           : ng.IQService,
    $uibModal    : ng.ui.bootstrap.IModalService,
    $window      : ng.IWindowService,
    API          : ApiModel,
    gridAPI      : any,
    sessionObjAPI: SessionServiceAjs,
    workshopAPI  : ApiServiceAjs
  ) {
    this.$q         = $q;
    this.$uibModal  = $uibModal;
    this.$window    = $window;
    this.api        = workshopAPI;
    this.API        = API;
    this.gridApi    = gridAPI;
    this.sessionApi = sessionObjAPI;
  }

  copyInvoice ( invoice : InvoiceModel ) : ng.IPromise<InvoiceModel> {
    return this.api.get('invoice/copy_invoice', invoice.id)
    .then(( invoice : InvoiceModel) => {
      this.sessionApi.setKey('invoice_copy', invoice);

      return invoice;
    });
  }

  getCount ( vehicle : VehicleModel ) : ng.IPromise<number> {
    return this.api.get('/vehicles/invoice_count', [ vehicle.id, true ]);
  }

  getFirstPage ( vehicle : VehicleModel ) : ng.IPromise<Array<InvoiceModel>> {
    return this.getInvoices(vehicle, {
      offset   : 0,
      pageSize : this.tableSettings.pagination.pageSize,
      field    : this.tableSettings.sorting.field,
      direction: this.tableSettings.sorting.direction
    });
  }

  getInvoiceItems ( invoice : InvoiceModel, params : any ) : ng.IPromise<Array<InvoiceItemModel>> {
    return this.api.get('/invoice_items/invoice_items_list', [
      invoice.id,
      params.offset,
      params.pageSize,
      params.field,
      params.direction
    ]);
  }

  getInvoiceItemsCount ( invoice : InvoiceModel ) : ng.IPromise<number> {
    return this.api.get('/invoice_items/invoice_items_list_count', [ invoice.id ]);
  }

  getInvoices ( vehicle : VehicleModel, params : any ) : ng.IPromise<Array<InvoiceModel>> {
    return this.api.get('/vehicles/invoice_list', [
      vehicle.id,
      params.offset,
      params.pageSize,
      params.field,
      params.direction,
      true
    ]);
  }

  search ( vehicle : VehicleModel, params, filter ) : ng.IPromise<Array<InvoiceModel>> {
    return this.api.get('/vehicles/invoice_list_with_find', [
      vehicle.id,
      filter,
      params.offset,
      params.pageSize,
      params.field,
      params.direction,
    ]);
  }

  searchCount ( vehicle : VehicleModel, filter ) : ng.IPromise<number> {
    return this.api.get('/vehicles/invoice_count_with_find', [
      vehicle.id,
      filter
    ]);
  }

  view ( vehicle : VehicleModel ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller       : 'VehicleHistoryCtrl',
      templateUrl      : VehicleHistoryHtml,
      windowClass      : 'x-lg-modal flip-container',
      windowTemplateUrl: WindowFlipHtml,
      resolve          : {
        invoices: () => this.getFirstPage(vehicle),
        vehicle : () => vehicle
      }
    }).result;
  }

  viewReport ( vehicle : VehicleModel ) : ng.IPromise<VehicleModel> {
    return this.$uibModal.open({
      bindToController: true,
      controller      : 'VehicleHistoryReportCtrl',
      controllerAs    : '$ctrl',
      templateUrl     : VehicleHistoryReportHtml,
      resolve         : {
        vehicle: () => vehicle
      }
    }).result
    .then(( settings : VehicleHistoryReportSettingsModel ) => {
      this.$window.open(`${this.API.url}/reports/jasper_vehicle_history/${ vehicle.id }/${ settings.printTotal }/${ settings.showSummary }.pdf`, '_blank');

      return vehicle;
    });
  }
}
