import { Injector, NgModule } from '@angular/core';

import { QuickbooksService } from '../quickbooks/quickbooks.service';
import { SharedModule }      from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : QuickbooksService,
      useFactory: ( $injector : Injector ) => $injector.get('Quickbooks')
    }
  ]
})
export class QuickbooksModule {}
