import { Component, Input }       from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import sprite from 'icons/workshop.sprite.svg';

import { IconThemeModel } from './typings/icon.theme';

@Component({
  selector   : 'wor-icon',
  styles: [ require('./icon.component.scss') ],
  template: require('./icon.component.html')
})
export class IconComponent {
  public _icon  : string | SafeHtml;
  public sprite : string = sprite;

  @Input() set icon ( _icon : string ) {
    if (this.isTheme('inline')) {
      this._icon = this.sanitizer.bypassSecurityTrustHtml(_icon);
    }

    this._icon = _icon;
  }

  @Input() theme : IconThemeModel = 'workshop';

  constructor (
    private sanitizer : DomSanitizer
  ) {}

  isTheme ( theme : IconThemeModel = 'workshop' ) : boolean {
    return theme === this.theme;
  }
}
