import 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';

import { CreateLoanCarCtrl }           from './loan-car-schedule.create';
import { CreateLoanCarEventCtrl }      from './loan-car-schedule.create-event';
import { CreateLoanCarInvoiceCtrl }    from './loan-car-schedule.create-invoice';
import { LoanCarService }              from './loan-car-schedule.service';
import { LoanCarActivateCtrl }         from './loan-car-schedule.activate';
import { LoanCarDiarySimplifiedCtrl }  from './loan-car-schedule.diary-simplified';
import { LoanCarEvents }               from './loan-car-schedule.event.service';
import { LoanCarInvoices }             from './loan-car-schedule.invoice.service';
import { LoanCarSchedule }             from './loan-car-schedule.schedule.service';
import { LoanCarScheduleCtrl }         from './loan-car-schedule';
import { LoanCarScheduleStates }       from './loan-car-schedule.states.ajs';
import { LoanStatus }                  from './loan-car-schedule.status.filter.ajs';
import { LoanCarScheduleStatusPipe }   from './loan-car-schedule.status.pipe';
import { LoanVehicleCtrl }             from './loan-car-schedule.vehicle';
import { LoanVehiclesCtrl }            from './loan-car-schedule.vehicles';
import { ModifyLoanCarCtrl }           from './loan-car-schedule.modify';
import { ModifyLoanCarEventCtrl }      from './loan-car-schedule.modify-event';
import { ModifyLoanCarInvoiceCtrl }    from './loan-car-schedule.modify-invoice';
import { ReturnLoanCarCtrl }           from './loan-car-schedule.return';
import { ReturnLoanCarEventCtrl }      from './loan-car-schedule.return-event';
import { ReturnLoanCarInvoiceCtrl }    from './loan-car-schedule.return-invoice';
import { ReviewLoanCarCtrl }           from './loan-car-schedule.review';
import { ReviewLoanCarEventCtrl }      from './loan-car-schedule.review-event';
import { ReviewLoanCarInvoiceCtrl }    from './loan-car-schedule.review-invoice';
import { StartLoanCarCtrl }            from './loan-car-schedule.start';
import { StartLoanCarEventCtrl }       from './loan-car-schedule.start-event';
import { StartLoanCarInvoiceCtrl }     from './loan-car-schedule.start-invoice';

angular.module('app-lite.loanCar', [])

.controller('CreateLoanCarCtrl',          CreateLoanCarCtrl)
.controller('CreateLoanCarEventCtrl',     CreateLoanCarEventCtrl)
.controller('CreateLoanCarInvoiceCtrl',   CreateLoanCarInvoiceCtrl)
.controller('LoanCarActivateCtrl',        LoanCarActivateCtrl)
.controller('LoanCarDiarySimplifiedCtrl', LoanCarDiarySimplifiedCtrl)
.controller('LoanCarScheduleCtrl',        LoanCarScheduleCtrl)
.controller('LoanVehicleCtrl',            LoanVehicleCtrl)
.controller('LoanVehiclesCtrl',           LoanVehiclesCtrl)
.controller('ModifyLoanCarCtrl',          ModifyLoanCarCtrl)
.controller('ModifyLoanCarEventCtrl',     ModifyLoanCarEventCtrl)
.controller('ModifyLoanCarInvoiceCtrl',   ModifyLoanCarInvoiceCtrl)
.controller('ReturnLoanCarCtrl',          ReturnLoanCarCtrl)
.controller('ReturnLoanCarEventCtrl',     ReturnLoanCarEventCtrl)
.controller('ReturnLoanCarInvoiceCtrl',   ReturnLoanCarInvoiceCtrl)
.controller('ReviewLoanCarCtrl',          ReviewLoanCarCtrl)
.controller('ReviewLoanCarEventCtrl',     ReviewLoanCarEventCtrl)
.controller('ReviewLoanCarInvoiceCtrl',   ReviewLoanCarInvoiceCtrl)
.controller('StartLoanCarCtrl',           StartLoanCarCtrl)
.controller('StartLoanCarEventCtrl',      StartLoanCarEventCtrl)
.controller('StartLoanCarInvoiceCtrl',    StartLoanCarInvoiceCtrl)

.factory('LoanCar',                       LoanCarService)
.factory('LoanCarEvents',                 LoanCarEvents)
.factory('LoanCarInvoices',               LoanCarInvoices)
.factory('LoanCarSchedule',               LoanCarSchedule)

.filter('loanStatus',                     LoanStatus)

.service('loanCarScheduleStatusPipe',     downgradeInjectable(LoanCarScheduleStatusPipe))

.config(LoanCarScheduleStates);