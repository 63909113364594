import { Injector, NgModule } from '@angular/core';

@NgModule({
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : 'businessIntelligenceApi',
      useFactory: ( $injector : Injector ) => $injector.get('businessIntelligenceApi')
    }
  ]
})
export class BusinessIntelligenceModule {}
